import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import Button from "../../../helpersComponents/Button/button";
import useParallelFetchJson from "../../../../hooks/fetchHooks/parallelJSON/useParallelFetchJson";
import TableComponentMemo from "../../../helpersComponents/TableComponent/tableComponent";
import { useSnackbar } from "notistack";
import useParallelsFetchErrorsSummary from "../../../../hooks/fetchHooks/useParallelsFetchErrorsSummary/useParallelsFetchErrorsSummary";

interface Props {
  rows: any[];
  fetchObject: UseFetchReturn;
  settingsLayoutName: string;
  ifWhite?: true;
  columns: ColumnTable[];
  parallerPatchPath: string;
  ifAdd?: boolean;
  ifEdit?: boolean;
  ifDelete?: boolean;
  deleteCommandName?: string;
  popup?: React.FC;
  openPopup?: boolean;
  commitChanges?: any;
  actionColumn?: React.FC;
  rowDetail?: any;
}

export default function TableAndActions({
  rows,
  fetchObject,
  settingsLayoutName,
  ifWhite,
  columns,
  parallerPatchPath,
  ifAdd,
  ifEdit,
  ifDelete,
  deleteCommandName,
  popup,
  openPopup,
  commitChanges,
  actionColumn,
  rowDetail,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchAgain } = fetchObject;
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isPutingPosting, setIsPutingPosting] = useState<boolean>(false);

  const formatRowCallback = useCallback(
    (row, errors) => {
      let objToRet = {
        employee: `${row.firstName} ${row.lastName}`,
        dateFrom: row.dateFrom,
        dateTo: row.dateTo,
        errors: errors.map((el) => (
          <>
            {t(el)}
            <br />
          </>
        )),
      };

      return objToRet;
    },
    [t]
  );

  const [setFetchResponse] = useParallelsFetchErrorsSummary(
    rows,
    [
      { name: "employee", title: t("employee") },
      { name: "dateFrom", title: t("date_from"), type: "date_hours" },
      { name: "dateTo", title: t("date_to2"), type: "date_hours" },
      { name: "errors", title: t("errors") },
    ],
    formatRowCallback
  );

  const [bodyPatch, setBodyPatch] = useState<any>(false);
  const result = useParallelFetchJson(
    "PATCH",
    parallerPatchPath,
    bodyPatch,
    selectedRows
  );

  useEffect(() => {
    if (result?.isFetching) return;
    setBodyPatch(false);

    if (result.isAllGood) {
      enqueueSnackbar(t("actions_completed_successfully"), {
        variant: "success",
      });
      fetchAgain();
      setIsPutingPosting(false);
    } else {
      if (!result.isAllFail) {
        enqueueSnackbar(t("not_all_actions_were_successful"), {
          variant: "warning",
        });
      }

      setFetchResponse(result);

      setTimeout(() => {
        fetchAgain();
        setIsPutingPosting(false);
      }, 1000);
    }
  }, [result, t, enqueueSnackbar, fetchAgain, setFetchResponse]);

  return (
    <>
      <div>
        <LoadingWrapper
          isLodadingProgress={fetchObject.isFetching || isPutingPosting}
          isError={fetchObject.isError}
          setIfFetchAgain={fetchAgain}
        >
          {!fetchObject.isFetching && !fetchObject.isError && (
            <TableComponentMemo
              layoutSettingName={settingsLayoutName}
              backgroundColor={ifWhite ? "White" : undefined}
              rows={rows}
              ifAdd={ifAdd}
              ifEdit={ifEdit}
              ifDelete={ifDelete}
              popup={popup}
              columns={columns}
              selectData={setSelectedRows}
              selectedData={selectedRows}
              deleteCommandName={deleteCommandName || t("cancel")}
              columnsWidth={[
                { columnName: "attachmentsList", width: "200" },
                { columnName: "lp", width: "100" },
              ]}
              tableEditColumnWidth={300}
              commitChanges={commitChanges}
              actionColumn={actionColumn}
              rowDetail={rowDetail}
            />
          )}
        </LoadingWrapper>
      </div>
      <div>
        <div className="acionButtonsContainerGlobal">
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setIsPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 7,
                  },
                ]);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setIsPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 2,
                  },
                ]);
              }}
            >
              {t("approve")}
            </Button>
          </div>
          <div>
            <Button
              className="actionButtonGlobal"
              width={300}
              onClick={() => {
                setIsPutingPosting(true);
                setBodyPatch([
                  {
                    op: "replace",
                    path: "/status",
                    value: 5,
                  },
                ]);
              }}
            >
              {t("deny")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
