import { useField } from "formik";
import React, { ReactElement } from "react";
import { TextFieldStyled } from "../../MaterialUi";
import ErrorLabel from "../ErrorLabel/errorLabel";

export default function TextFieldFormik({
  label,
  showTopLabel,
  onChangeOwn,
  onChangeExtra,
  mask,
  ...props
}: any): ReactElement {
  const [field, meta] = useField(props);
  const { onChange } = field;

  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}
      <TextFieldStyled
        label={label}
        {...field}
        {...props}
        mask={mask}
        isError={meta.touched && meta.error}
        onChange={(e) => {
          if (onChangeOwn) {
            onChangeOwn(e);
            return;
          }
          if (onChangeExtra) {
            onChangeExtra(e);
          }
          onChange(e);
        }}
      />
      {meta.touched && meta.error ? (
        <ErrorLabel errorName={meta.error} />
      ) : null}
    </div>
  );
}
