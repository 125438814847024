import NavigationReception from "./Reception/navigation";

import NarrowNavigationAgent from "./Agent/narrowNav";
import NavigationAgent from "./Agent/navigation";

import NarrowNavigationEmployee from "./Employee/narrowNav";
import NavigationEmployee from "./Employee/navigation";

import NarrowNavigationPfso from "./PFSO/narrowNav";
import NavigationPfso from "./PFSO/navigation";

import NarrowNavigationReception from "./Reception/narrowNav";
import NavigationSecurity from "./Security/navigation";

import NarrowNavigationSecurity from "./Security/narrowNav";

import NarrowNavigationShiftDirector from "./ShiftDirector/narrowNav";
import NavigationShiftDirector from "./ShiftDirector/navigation";

import NarrowNavigationSuperiorAc from "./SuperiorAc/narrowNav";
import NavigationSuperiorAc from "./SuperiorAc/navigation";

import NarrowNavigationSuperiorTime from "./SuperiorTime/narrowNav";
import NavigationSuperiorTime from "./SuperiorTime/navigation";

import NarrowNavigationWorkerAc from "./WorkerAc/narrowNav";
import NavigationWorkerAc from "./WorkerAc/navigation";

import NarrowNavigationWorkerTime from "./WorkerTime/narrowNav";
import NavigationWorkerTime from "./WorkerTime/navigation";

import NavigationOhs from "./OHS/navigation";
import NarrowNavigationOhs from "./OHS/narrowNav";

import NavigationOperationalSupport from "./OperationalSupport/navigation";
import NarrowNavigationOperationalSupport from "./OperationalSupport/narrowNav";

import NarrowNavigationEventsPreview from "./EventsPreview/narrowNav";
import NavigationEventsPreview from "./EventsPreview/navigation";

import NarrowNavigationGuard from "./Guard/narrowNav";
import NavigationGuard from "./Guard/navigation";

import NarrowNavigationLockersManagement from "./LockersManagement/narrowNav";
import NavigationLockersManagement from "./LockersManagement/navigation";

export const profileNavigationComponentsDictionary = {
  0: <NavigationSuperiorAc />,
  1: <NavigationSuperiorTime />,
  2: <NavigationWorkerAc />,
  3: <NavigationWorkerTime />,
  5: <NavigationAgent />,
  6: <NavigationReception />,
  7: <NavigationGuard />,
  8: <NavigationPfso />,
  9: <NavigationShiftDirector />,
  10: <NavigationSecurity />,
  11: <NavigationEmployee />,
  12: <NavigationOhs />,
  13: <NavigationOperationalSupport />,
  15: <NavigationEventsPreview />,
  16: <NavigationLockersManagement />,
};

export const profileNarrowNavigationComponentsDictionary = {
  0: <NarrowNavigationSuperiorAc />,
  1: <NarrowNavigationSuperiorTime />,
  2: <NarrowNavigationWorkerAc />,
  3: <NarrowNavigationWorkerTime />,
  5: <NarrowNavigationAgent />,
  6: <NarrowNavigationReception />,
  7: <NarrowNavigationGuard />,
  8: <NarrowNavigationPfso />,
  9: <NarrowNavigationShiftDirector />,
  10: <NarrowNavigationSecurity />,
  11: <NarrowNavigationEmployee />,
  12: <NarrowNavigationOhs />,
  13: <NarrowNavigationOperationalSupport />,
  15: <NarrowNavigationEventsPreview />,
  16: <NarrowNavigationLockersManagement />,
};

export const profileTranslationKeysDictionary = {
  0: "superior_ac",
  1: "superior_time",
  2: "worker_ac",
  3: "worker_time",
  4: "administrator_ac",
  5: "agent",
  6: "reception",
  7: "guard",
  8: "pfso",
  9: "shift_director",
  10: "security",
  11: "employee",
  12: "ohs",
  13: "operational_support",
  15: "events_preview",
  16: "lockers_management",
};

export const colorBackgroundClassNameDictionary = {
  0: "acSuperior",
  1: "taSuperior",
  2: "acWorker",
  3: "taWorker",
  4: "acAdministrator",
  5: "agent",
  6: "reception",
  7: "guard",
  8: "pfso",
  9: "shiftDirector",
  10: "security",
  11: "employee",
  12: "ohs",
  13: "operationalSupport",
  15: "eventsPreview",
  16: "lockersManagement",
};

export const colorClassNameDictionary = {
  0: "acSuperiorColor",
  1: "taSuperiorColor",
  2: "acWorkerColor",
  3: "taWorkerColor",
  4: "acAdministratorColor",
  5: "agentColor",
  6: "receptionColor",
  7: "guardColor",
  8: "pfsoColor",
  9: "shiftDirectorColor",
  10: "securityColor",
  11: "employeeColor",
  12: "ohsColor",
  13: "operationalSupportColor",
  15: "eventsPreviewColor",
  16: "lockersManagementColor",
};

export const colorNameDictionary = {
  0: "#1C84C6",
  1: "#50b8ca",
  2: "#e44343",
  3: "#1AB394",
  4: "purple",
  5: "#63ace5",
  6: "#2BAE66FF",
  7: "#FF5733",
  8: "#f8a12f",
  9: "#DAA03DFF",
  10: "#6969B3",
  11: "#66be3d",
  12: "#6f6ff7",
  13: "#D67575",
  15: "#FF33F3",
  16: "#2979ff",
};

export const activeColorNameDictionary = {
  0: "#1376b3",
  1: "#47afc2",
  2: "#ca3636",
  3: "#219981",
  4: "purple",
  5: "#79bff5",
  6: "#1C884C",
  7: "#FF5C23",
  8: "#eb921e",
  9: "#C78C27",
  10: "#4747c7",
  11: "#5CAD36",
  12: "#7777e9",
  13: "#D67575",
  15: "#FF33F3",
  16: "#304ffe",
};

export const colorWithHoverClassNameDictionary = {
  0: "acSuperiorWithHover",
  1: "taSuperiorWithHover",
  2: "acWorkerWithHover",
  3: "taWorkerWithHover",
  4: "acAdministratorWithHove",
  5: "agentWithHover",
  6: "receptionWithHover",
  7: "guardWithHover",
  8: "pfsoWithHover",
  9: "shiftDirectorWithHover",
  10: "securityWithHover",
  11: "employeeWithHover",
  12: "ohsWithHover",
  13: "operationalSupportWithHover",
  15: "eventsPreviewWithHover",
  16: "lockersManagementWithHover",
};

export const profilesRoutesDictionary = {
  0: "superior-ac",
  1: "superior-time",
  2: "worker-ac",
  3: "worker-time",
  4: "administrator-ac",
  5: "agent",
  6: "reception",
  7: "guard",
  8: "pfso",
  9: "shift-director",
  10: "security",
  11: "employee",
  12: "ohs",
  13: "operational-support",
  15: "events-preview",
  16: "lockers-management",
};
