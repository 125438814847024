import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import {
  faBuilding,
  faEdit,
  faFireAlt,
  faHardHat,
  faInfo,
  faListUl,
  faPeopleArrows,
  faShip,
  faTruck,
  faUser,
  faUserFriends,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ArrivalsOfShipsPfso from "../../pfso/ArrivalsOfShips";
import CrewDescentsPfso from "../../pfso/CrewDescents";
import CrewEntriesPfso from "../../pfso/CrewEntries";
import CompaniesPfso from "../../pfso/DeliveryGarbageCompanies";
import FireReportPfso from "../../pfso/FireReport";
import GuestsPfso from "../../pfso/Guests";
import GuestsEventsReportPfso from "../../pfso/GuestsEventsReport";
import GuestsVisitsReportPfso from "../../pfso/GuestsVisitsReport";
import SupportPfso from "../../pfso/Support";
import NotificationInPortsPFSO from "../../pfso/NotificationsInPorts";
import PreApprovedCompanies from "../../pfso/NotificationsInPorts/PreApprovedCompanies";
import ServicesAndDeliveriesPfso from "../../pfso/ServicesAndDeliveries";
import UsersManagementPfso from "../../pfso/UsersManagement";
import WhiteListCompaniesPfso from "../../pfso/WhiteList/WhiteListCompaniesPfso";
import WhiteListGuestsPfso from "../../pfso/WhiteList/WhiteListGuestsPfso";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import {
  selectPFSOCrewDescentsMenuItemName,
  selectPFSOCrewEntriesMenuItemName,
  selectPFSONotificationMenuItemName,
  selectPFSOServicesAndDeliveriesMenuItemName,
} from "../../../reducers/notificationsSummary";
import NotificationInPort from "../../pfso/NotificationInPort";
import BlackListGuestsPfso from "../../pfso/BlackList/BlackListGuestsPfso";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const notificationsMenuItemName = useAppSelector(
    selectPFSONotificationMenuItemName
  );

  const servicesAndDeliveriesMenuItemName = useAppSelector(
    selectPFSOServicesAndDeliveriesMenuItemName
  );

  const crewEntriesMenuItemName = useAppSelector(
    selectPFSOCrewEntriesMenuItemName
  );

  const crewDescentsMenuItemName = useAppSelector(
    selectPFSOCrewDescentsMenuItemName
  );

  const navLinksArrayTimeWorker: navLinksArrayTimeWorkerInterface = {
    profileId: 8,
    hidenInNavBar: [
      {
        ifHidden: false,
        path: "/pfso/notifications/:id",
        component: (props) => <NotificationInPort {...props} />,
      },
      {
        ifHidden: false,
        path: "/pfso/services-and-deliveries/:id",
        component: (props) => <ServicesAndDeliveriesPfso {...props} />,
      },

      {
        ifHidden: false,
        path: "/pfso/crew-entries/:id",
        component: (props) => <CrewEntriesPfso {...props} />,
      },

      {
        ifHidden: false,
        path: "/pfso/crew-descents/:id",
        component: (props) => <CrewDescentsPfso {...props} />,
      },
    ],
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/pfso/profile",
        component: () => <Profile />,
      },
      {
        icon: faUserFriends,
        name: notificationsMenuItemName,
        ifHidden: false,
        singleLink: false,
        path: [
          {
            name: notificationsMenuItemName,
            path: "/pfso/home",
            ifHidden: false,
            component: () => <NotificationInPortsPFSO />,
          },
          {
            name: `${t("companies")} ${t("pre_approved").toLowerCase()}`,
            path: "/pfso/pre-approved-companies",
            ifHidden: false,
            component: () => <PreApprovedCompanies />,
          },
        ],
      },
      {
        icon: faTruck,
        name: servicesAndDeliveriesMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/pfso/services-and-deliveries",
        component: () => <ServicesAndDeliveriesPfso />,
      },
      {
        icon: faShip,
        name: t("arrival_of_ships"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/arrival-of-ships",
        component: () => <ArrivalsOfShipsPfso />,
      },
      {
        icon: faUserPlus,
        name: crewEntriesMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/pfso/crew-entries",
        component: () => <CrewEntriesPfso />,
      },
      {
        icon: faUserMinus,
        name: crewDescentsMenuItemName,
        ifHidden: false,
        singleLink: true,
        path: "/pfso/crew-descents",
        component: () => <CrewDescentsPfso />,
      },
      {
        icon: faAddressBook,
        name: t("events_report"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/guests-events",
        component: () => <GuestsEventsReportPfso />,
      },
      {
        icon: faFireAlt,
        name: t("fire_report"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/fire-report",
        component: () => <FireReportPfso />,
      },
      {
        icon: faListUl,
        name: t("visits_report"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/visits-report",
        component: () => <GuestsVisitsReportPfso />,
      },
      {
        icon: faEdit,
        name: t("white_list"),
        ifHidden: false,
        singleLink: false,
        path: [
          {
            name: t("companies"),
            path: "/pfso/white-list-companies",
            ifHidden: false,
            component: () => <WhiteListCompaniesPfso />,
          },
          {
            name: t("persons"),
            path: "/pfso/white-list-persons",
            ifHidden: false,
            component: () => <WhiteListGuestsPfso />,
          },
        ],
      },
      {
        icon: faEdit,
        name: t("black_list"),
        ifHidden: false,
        singleLink: false,
        path: [
          {
            name: t("persons"),
            path: "/pfso/black-list-persons",
            ifHidden: false,
            component: () => <BlackListGuestsPfso />,
          },
        ],
      },
      {
        icon: faPeopleArrows,
        name: t("support"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/support",
        component: () => <SupportPfso />,
      },
      {
        icon: faHardHat,
        name: t("users_management"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/management-users",
        component: () => <UsersManagementPfso />,
      },
      {
        icon: faUser,
        name: t("persons"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/persons",
        component: () => <GuestsPfso />,
      },
      {
        icon: faBuilding,
        name: t("delivery_man_and_garbage_company"),
        ifHidden: false,
        singleLink: true,
        path: "/pfso/delivery-garbage-company",
        component: () => <CompaniesPfso />,
      },
    ],
  };

  return navLinksArrayTimeWorker;
};
export default useNavLinksArray;
