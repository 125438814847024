import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { selectTimeWorkerProfile } from "../../../reducers/profiles";
import { selectAuthUser } from "../../../reducers/session";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectSettings } from "../../../reducers/settings";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import TimeWithDescription from "../NumberWithDescription/numberWithDescription";
import "./holidayLimitBrief.scss";
import { useAppSelector } from "../../../store/hooks";
import { bankersRound } from "../../../HelpersFunctions/math/rounding/bankersRound";
import { getLocalizedHoursString } from "../../../HelpersFunctions/dateAndTime/getLocalizedHoursString";
import { getLocalizedDaysString } from "../../../HelpersFunctions/dateAndTime/getLocalizedDaysString";
import { ProfileType } from "../../../enums/profileType";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
//import { convertTime } from "../../../HelpersFunctions/dateAndTime/convertTime";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { AbsenceUnit } from "../../../Constants/absenceUnit";

interface HolidayLimitBriefInterface {
  size?: "small";
  hideTop?: boolean;
  addVacationButton?: boolean;
  goToRemainedHolidaysLimitsButton?: boolean;
  year?: number;
  timeWorkerId?: number;
  selectedRequestType?: number;
}

const HolidayLimitBrief = ({
  size,
  hideTop,
  addVacationButton,
  goToRemainedHolidaysLimitsButton,
  year,
  timeWorkerId,
  selectedRequestType,
}: HolidayLimitBriefInterface) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);
  const profiles = useAppSelector(selectTimeWorkerProfile);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [holidayLimit, setHolidayLimit] = useState<any[]>([]);
  const [remainedLeave, setRemainedLeave] = useState<any[]>([]);

  const [holidaysLimits, setHolidaysLimits] =
    useState<TimeWorkerHolidayLimitItem[]>();

  const [holidaysLimitsDataFetched, setHolidaysLimitsDataFetched] =
    useState<boolean>(false);

  const history = useHistory();

  /*let holidayHoursLimitInDaysOption: boolean =
    settings.WebWymiarGodzinowyWDniach?.value === "1";

  const modifyTimeWorkersHolidaysLimits = useCallback(
    (data) => {
      if (data) {
        data.forEach((el) => {
          el.duration = convertTime(
            el.duration,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.overdue = convertTime(
            el.overdue,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.used = convertTime(
            el.used,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.remained = convertTime(
            el.remained,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.remainedOverdue = convertTime(
            el.remainedOverdue,
            el.isDay,
            holidayHoursLimitInDaysOption
          );

          el.isDay = el.isDay
            ? t("days").toLowerCase()
            : t("hours").toLowerCase();
        });
      }
    },
    [holidayHoursLimitInDaysOption, t]
  );*/

  const modifyTimeWorkersHolidaysLimitsInfoResponseCallback = useCallback(
    (responseData: TimeWorkerHolidaysLimits): TimeWorkerHolidayLimitItem[] => {
      setHolidaysLimitsDataFetched(true);

      let tempResponse: any = responseData ? responseData.limits : [];
      //modifyTimeWorkersHolidaysLimits(tempResponse);
      setHolidaysLimits(tempResponse);

      return tempResponse;
    },
    []
  );

  const successCallback = useCallback(
    (data) => {
      setHolidaysLimitsDataFetched(true);

      let tempResponse: TimeWorkerHolidaysLimits[] = data?.resJson
        ? data.resJson
        : [];

      const foundTimeWorker = tempResponse.find(
        (el) => el.timeWorkerId === timeWorkerId
      );
      if (foundTimeWorker) {
        setHolidaysLimits(foundTimeWorker.limits);
      }
    },
    [timeWorkerId]
  );

  const [holidaysLimitsInfoBody, setHolidaysLimitsInfoBody] = useState<
    string | boolean
  >(false);

  const [
    fetchingStateTimeSuperiorHolidaysLimitsInfo,
    fetchAgainTimeSuperiorHolidaysLimitsInfo,
  ] = useFetchOtherThanGET({
    path: `superior-time/holiday-limits`,
    method: "POST",
    body: holidaysLimitsInfoBody,
    setBody: setHolidaysLimitsInfoBody,
    contentType: "application/json",
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (holidaysLimitsInfoBody) {
      fetchAgainTimeSuperiorHolidaysLimitsInfo();
    }
  }, [holidaysLimitsInfoBody, fetchAgainTimeSuperiorHolidaysLimitsInfo]);

  const [
    ,
    fetchingStateTimeWorkerHolidaysLimitsInfo,
    fetchAgainTimeWorkerHolidaysLimitsInfo,
  ] = useFetchAndSetGET({
    path: `worker-time/${authUserId}/holiday-limits-info`,
    modifyResponseCallback: modifyTimeWorkersHolidaysLimitsInfoResponseCallback,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (!holidaysLimitsDataFetched) {
      if (profileType === ProfileType.SUPERIOR_TIME) {
        if (timeWorkerId && timeWorkerId > 0 && year && year > 0) {
          let queryBody = {
            timeWorkersIds: [timeWorkerId],
            year: year,
          };

          setHolidaysLimits([]);
          setHolidaysLimitsInfoBody(JSON.stringify(queryBody));
        }
      } else if (profileType === ProfileType.WORKER_TIME) {
        if (selectedRequestType && selectedRequestType > 0) {
          fetchAgainTimeWorkerHolidaysLimitsInfo();
        }
      }
    }
  }, [
    timeWorkerId,
    year,
    selectedRequestType,
    profileType,
    fetchAgainTimeWorkerHolidaysLimitsInfo,
    holidaysLimitsDataFetched,
  ]);

  useEffect(() => {
    if (timeWorkerId) {
      setHolidaysLimitsDataFetched(false);
    }
  }, [timeWorkerId]);

  const limitOfHolidays: {
    holiday: number;
    onDemand: number;
    training: number;
    childCare: number;
  } = {
    holiday: 1,
    onDemand: 2,
    training: 4,
    childCare: 8,
  };

  type elementsNamesType =
    | "overdueLeaveOverdueLeave"
    | "leaveDuration"
    | "overdueLeave"
    | "leaveOnDemandDuration"
    | "educationalLeaveDuration"
    | "careA188Duration"
    | "remainedLeave"
    | "leaveFromRequests"
    | "remainedLeaveOnDemand"
    | "remainedEducationalLeave"
    | "remainedCareA188"
    | "anotherHolidayLimit";

  let currentLanguage = JSON.parse(
    localStorage.getItem("languageAndOptionId") || "{}"
  );

  if (currentLanguage) {
    currentLanguage = currentLanguage[0];
  } else {
    currentLanguage = "pl";
  }

  const convertUnitAndTime = useCallback(
    (unitNumberString: "1" | "2" | "3" | "4", time: number) => {
      switch (unitNumberString) {
        case "1":
          return {
            unit: getLocalizedHoursString(time, currentLanguage),
            time: time.toString(),
          };
        case "2":
          return {
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
          };
        case "3":
          let days = parseInt(bankersRound(time / 8, 2).toString());
          let hours = parseInt(bankersRound(time % 8, 2).toString());
          let unit = "";

          if (hours > 0) {
            unit = getLocalizedHoursString(hours, currentLanguage);
          }

          return {
            unit: unit,
            time: `${days} ${getLocalizedDaysString(days, currentLanguage)} ${
              hours > 0 ? hours : ""
            }`,
          };
        case "4":
          return {
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
            time: bankersRound(time / 8, 2).toString(),
          };
        default:
          return {
            unit: getLocalizedHoursString(time, currentLanguage),
            time: time.toString(),
          };
      }
    },
    [currentLanguage]
  );

  const unitTimeSettings = useCallback(
    (time: number): { time: string; unit: string } => {
      const unitNumberString = settings.WebWymusTypWymiaruUrlopu?.value;
      const converted = convertUnitAndTime(unitNumberString, time);
      return {
        time: converted.time,
        unit: converted.unit,
      };
    },
    [convertUnitAndTime, settings.WebWymusTypWymiaruUrlopu?.value]
  );

  const formElementUnitAndTime = useCallback(
    (
      time: number,
      absenceUnit: AbsenceUnit,
      elementName: elementsNamesType
    ): { time: string; unit: string } => {
      if (time === undefined) {
        return { time: "0", unit: getLocalizedDaysString(0, currentLanguage) };
      }

      if (
        settings?.WebWymusTypWymiaruUrlopu &&
        (settings.WebWymusTypWymiaruUrlopu.value === "1" ||
          settings.WebWymusTypWymiaruUrlopu.value === "2" ||
          settings.WebWymusTypWymiaruUrlopu.value === "3" ||
          settings.WebWymusTypWymiaruUrlopu.value === "4")
      ) {
        return unitTimeSettings(time);
      }

      if (
        elementName === "overdueLeaveOverdueLeave" ||
        elementName === "leaveDuration" ||
        elementName === "overdueLeave" ||
        elementName === "remainedLeave" ||
        elementName === "leaveFromRequests"
      ) {
        if (absenceUnit === AbsenceUnit.DAYS) {
          return {
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
          };
        }
      } else if (
        elementName === "leaveOnDemandDuration" ||
        elementName === "remainedLeaveOnDemand"
      ) {
        if (absenceUnit === AbsenceUnit.DAYS) {
          return {
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
          };
        }
      } else if (
        elementName === "educationalLeaveDuration" ||
        elementName === "remainedEducationalLeave"
      ) {
        if (absenceUnit === AbsenceUnit.DAYS) {
          return {
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
          };
        }
      } else if (
        elementName === "careA188Duration" ||
        elementName === "remainedCareA188"
      ) {
        if (absenceUnit === AbsenceUnit.DAYS) {
          return {
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
          };
        }
      } else {
        if (absenceUnit === AbsenceUnit.DAYS) {
          return {
            time: parseInt(bankersRound(time / 8, 2).toString()).toString(),
            unit: getLocalizedDaysString(
              bankersRound(time / 8, 2),
              currentLanguage
            ),
          };
        }
      }

      return {
        time: time.toString(),
        unit: getLocalizedHoursString(time, currentLanguage),
      };
    },
    [currentLanguage, settings.WebWymusTypWymiaruUrlopu, unitTimeSettings]
  );

  interface ObjectInterface {
    name?: string;
    description?: string;
    time?: string;
    unit?: string;
  }

  const holidayLimitElement = useCallback(
    (elementName: elementsNamesType): ObjectInterface => {
      let description = "";

      switch (elementName) {
        case "overdueLeaveOverdueLeave":
          description = t("holiday_string");
          if (settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "leaveDuration":
          description = t("holidays_limit_2");
          break;

        case "overdueLeave":
          description = t("outstanding_holidays");
          break;

        case "leaveOnDemandDuration":
          description = t("on_demand");
          if (settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "educationalLeaveDuration":
          description = t("training");
          if (settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "careA188Duration":
          description = t("child_care");
          if (settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName].value;
          }
          break;

        case "remainedLeave":
          description = t("holiday_string");
          if (settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Wypoczynkowy_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "remainedLeaveOnDemand":
          description = t("on_demand");
          if (settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_NaZadanie_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "leaveFromRequests":
          description = t("holiday_with_request");
          break;

        case "remainedEducationalLeave":
          description = t("training");
          if (settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Szkoleniowy_" + profiles.workTimeSystemName]
                .value;
          }
          break;

        case "remainedCareA188":
          description = t("child_care");
          if (settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName]) {
            description =
              settings["Web_MUW_Art188Kp_" + profiles.workTimeSystemName].value;
          }
          break;

        case "anotherHolidayLimit":
          description = "";
          break;
      }

      if (holidaysLimits && holidaysLimits.length > 0) {
        if (elementName === "overdueLeaveOverdueLeave") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 1
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.duration + foundHolidayLimit.overdue,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "overdueLeaveOverdueLeave"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "leaveDuration") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 1
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.duration,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "leaveDuration"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "overdueLeave") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 1
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.overdue,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "overdueLeave"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "leaveOnDemandDuration") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 3
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.duration,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "leaveOnDemandDuration"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "educationalLeaveDuration") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 2
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.duration,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "educationalLeaveDuration"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "careA188Duration") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 4
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.duration,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "careA188Duration"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "remainedLeave") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 1
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.remained,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "remainedLeave"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "remainedLeaveOnDemand") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 3
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.remained,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "remainedLeaveOnDemand"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "leaveFromRequests") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 1
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.fromRequests,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "leaveFromRequests"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "remainedEducationalLeave") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 2
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.remained,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "remainedEducationalLeave"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "remainedCareA188") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === 4
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.remained,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "remainedCareA188"
            );

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        } else if (elementName === "anotherHolidayLimit") {
          const foundHolidayLimit = holidaysLimits.find(
            (el) => el.absenceTypeId === selectedRequestType
          );

          if (foundHolidayLimit) {
            const timeAndUnit = formElementUnitAndTime(
              foundHolidayLimit.remained,
              foundHolidayLimit.isDay ? AbsenceUnit.DAYS : AbsenceUnit.HOURS,
              "remainedCareA188"
            );

            description = foundHolidayLimit.absenceTypeName;

            return {
              time: timeAndUnit.time,
              description: description,
              unit: timeAndUnit.unit,
            };
          }
        }
      }

      return {
        time: "0",
        description: description,
        unit: t("days"),
      };
    },
    [
      selectedRequestType,
      holidaysLimits,
      formElementUnitAndTime,
      profiles.workTimeSystemName,
      settings,
      t,
    ]
  );

  let WebWyswietlaneTypyUrlopuGlownego =
    settings?.WebWyswietlaneTypyUrlopuGlownego?.value;
  if (!WebWyswietlaneTypyUrlopuGlownego) WebWyswietlaneTypyUrlopuGlownego = 1;

  let WebWyswietlaneTypyUrlopuPozostalo =
    settings?.WebWyswietlaneTypyUrlopuPozostalo?.value;
  if (!WebWyswietlaneTypyUrlopuPozostalo) WebWyswietlaneTypyUrlopuPozostalo = 3;

  let ifSmall = "";
  if (size === "small") ifSmall = "Small";

  const GoToRemainedHolidaysLimitsButton = useMemo(
    () => (
      <div className="addVacationButtonContainer">
        {t("limit_of_holidays")}
        <div
          className="addVacationButton"
          onClick={() => {
            history.push({
              pathname: "/worker-time/profile",
              state: { startDate: new Date(), endDate: new Date() },
            });
          }}
        >
          <div>
            <FontAwesomeIcon
              className="addVacationButtonIcon"
              icon={faPlusCircle}
              size="2x"
            />
          </div>
          <div className="addVacationButtonText">
            {t("go_to_remained_holidays_limits")}
          </div>
        </div>
      </div>
    ),
    [history, t]
  );

  const ApplicationForHolidayButton = useMemo(
    () => (
      <div className="addVacationButtonContainer">
        {t("holiday_left")}{" "}
        <div
          className="addVacationButton"
          onClick={() => {
            history.push({
              pathname: "/worker-time/holiday",
              state: { startDate: new Date(), endDate: new Date() },
            });
          }}
        >
          <div>
            <FontAwesomeIcon
              className="addVacationButtonIcon"
              icon={faPlusCircle}
              size="2x"
            />
          </div>
          <div className="addVacationButtonText">
            {t("application_for_holiday")}
          </div>
        </div>
      </div>
    ),
    [history, t]
  );

  useEffect(() => {
    if (holidaysLimits) {
      // Wymiary urlopów
      let tempHolidaysLimitsData: any[] = [
        {
          name: goToRemainedHolidaysLimitsButton
            ? GoToRemainedHolidaysLimitsButton
            : t("limit_of_holidays"),
        },
        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.holiday) ===
        limitOfHolidays.holiday
          ? [holidayLimitElement("overdueLeaveOverdueLeave")]
          : []),

        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.holiday) ===
        limitOfHolidays.holiday
          ? [holidayLimitElement("leaveDuration")]
          : []),

        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.holiday) ===
        limitOfHolidays.holiday
          ? [holidayLimitElement("overdueLeave")]
          : []),

        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.onDemand) ===
        limitOfHolidays.onDemand
          ? [holidayLimitElement("leaveOnDemandDuration")]
          : []),

        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.training) ===
        limitOfHolidays.training
          ? [holidayLimitElement("educationalLeaveDuration")]
          : []),

        ...((WebWyswietlaneTypyUrlopuGlownego & limitOfHolidays.childCare) ===
        limitOfHolidays.childCare
          ? [holidayLimitElement("careA188Duration")]
          : []),
      ];

      setHolidayLimit(tempHolidaysLimitsData);

      // Pozostały urlop
      let excludedRequestType: number[] = [1, 2, 3, 4];
      let tempRemainedLeavesData: any[] = [];

      let foundHolidayLimit = holidaysLimits.find(
        (el) => el.absenceTypeId === selectedRequestType
      );

      if (
        selectedRequestType &&
        !excludedRequestType.includes(selectedRequestType) &&
        foundHolidayLimit
      ) {
        tempRemainedLeavesData = [
          {
            name: addVacationButton
              ? ApplicationForHolidayButton
              : t("holiday_left"),
          },
        ];

        tempRemainedLeavesData.push(holidayLimitElement("anotherHolidayLimit"));
      } else {
        tempRemainedLeavesData = [
          {
            name: addVacationButton
              ? ApplicationForHolidayButton
              : t("holiday_left"),
          },

          ...((WebWyswietlaneTypyUrlopuPozostalo & limitOfHolidays.holiday) ===
          limitOfHolidays.holiday
            ? [holidayLimitElement("remainedLeave")]
            : []),

          holidayLimitElement("leaveFromRequests"),

          ...((WebWyswietlaneTypyUrlopuPozostalo & limitOfHolidays.onDemand) ===
          limitOfHolidays.onDemand
            ? [holidayLimitElement("remainedLeaveOnDemand")]
            : []),

          ...((WebWyswietlaneTypyUrlopuPozostalo & limitOfHolidays.training) ===
          limitOfHolidays.training
            ? [holidayLimitElement("remainedEducationalLeave")]
            : []),

          ...((WebWyswietlaneTypyUrlopuPozostalo &
            limitOfHolidays.childCare) ===
          limitOfHolidays.childCare
            ? [holidayLimitElement("remainedCareA188")]
            : []),
        ];
      }

      setRemainedLeave(tempRemainedLeavesData);
    }
  }, [
    holidaysLimits,
    ApplicationForHolidayButton,
    WebWyswietlaneTypyUrlopuPozostalo,
    addVacationButton,
    holidayLimitElement,
    limitOfHolidays.childCare,
    limitOfHolidays.holiday,
    limitOfHolidays.onDemand,
    limitOfHolidays.training,
    selectedRequestType,
    GoToRemainedHolidaysLimitsButton,
    WebWyswietlaneTypyUrlopuGlownego,
    goToRemainedHolidaysLimitsButton,
    t,
  ]);

  return (
    <div className={"holidayLimitBrief" + ifSmall}>
      {settings?.WebUkryjWymiarUrlopu?.value === "1" ? null : (
        <LoadingWrapper
          isLodadingProgress={
            fetchingStateTimeSuperiorHolidaysLimitsInfo.isFetching ||
            fetchingStateTimeWorkerHolidaysLimitsInfo.isFetching
          }
          isError={
            fetchingStateTimeSuperiorHolidaysLimitsInfo.isError ||
            fetchingStateTimeWorkerHolidaysLimitsInfo.isError
          }
          setIfFetchAgain={() => {
            if (profileType === ProfileType.SUPERIOR_TIME) {
              fetchAgainTimeSuperiorHolidaysLimitsInfo();
            } else if (profileType === ProfileType.WORKER_TIME) {
              fetchAgainTimeWorkerHolidaysLimitsInfo();
            }
          }}
        >
          {!fetchingStateTimeSuperiorHolidaysLimitsInfo.isFetching &&
            !fetchingStateTimeWorkerHolidaysLimitsInfo.isFetching &&
            !fetchingStateTimeSuperiorHolidaysLimitsInfo.isError &&
            !fetchingStateTimeWorkerHolidaysLimitsInfo.isError && (
              <>
                {holidayLimit && hideTop !== true && (
                  <div>
                    <TimeWithDescription size={size} parametrs={holidayLimit} />
                  </div>
                )}

                <div>
                  {remainedLeave && (
                    <TimeWithDescription
                      size={size}
                      parametrs={remainedLeave}
                    />
                  )}
                </div>
              </>
            )}
        </LoadingWrapper>
      )}
    </div>
  );
};

export default HolidayLimitBrief;
