import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { Button } from "@material-ui/core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import PopupAddLockersToEmployee from "./Popups/PopupAddLockersToEmployee";
function LockersContent() {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "lockersModuleName", title: t("locker_module") },
    { name: "lockerName", title: t("locker") },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: LockerAssignmentToEmployeeHistoryItem[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          employee: `${row.firstName} ${row.lastName}`,
        };
      });
      return modified;
    },
    []
  );

  const [
    lockersAssignmentToEmployees,
    ,
    fetchAgainLockersAssignmentToEmployees,
  ] = useFetchAndSetGET({
    path: `lockers-management/lockers-assignment-to-employees`,
    startFetchOnInitial: true,
    modifyResponseCallback: modifyResponseCallback,
  });

  const [lockerToRemove, setLockerToRemove] = useState<any>(false);
  const [, fetchAgainRemove] = useFetchOtherThanGET({
    path:
      `lockers-management/lockers-assignment-to-employees?` +
      `lockersModuleId=${lockerToRemove?.lockersModuleId}&acWorkerId=${lockerToRemove?.acWorkerId}&lockerId=${lockerToRemove?.lockerId}`,
    method: "DELETE",
    setBody: setLockerToRemove,
    successCallback: fetchAgainLockersAssignmentToEmployees,
  });

  useEffect(() => {
    if (lockerToRemove) {
      console.log(
        `#################USUWANIE: ${JSON.stringify(
          lockerToRemove
        )}###########################`
      );
      fetchAgainRemove();
    }
  }, [lockerToRemove, fetchAgainRemove]);

  return (
    <>
      <TopBanner pathName={t("lockers")} />
      <div className="flexAndCenter">
        <div>
          <TableComponent
            rows={lockersAssignmentToEmployees || []}
            columns={columns}
            layoutSettingName="layoutSuperiorAcLockersManagement"
            actionsColumnUpdated={{
              popup: PopupAddLockersToEmployee,
              addButton: {
                ifShow: true,
              },
              successCallbackPopup: fetchAgainLockersAssignmentToEmployees,
              removeButton: {
                ifShow: true,
                name: t("release_locker"),
                customComponent: (row: LockerAssignmentToEmployee) => {
                  return (
                    <>
                      <Button>
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="lg"
                          title={t("delete")}
                          style={{ padding: "5px" }}
                          onClick={async () => {
                            let selectedbuttonId = await confirm({
                              text: `${t(
                                "are_you_sure_you_want_to_release_locker"
                              )}`,
                              buttons: [
                                {
                                  buttonName: t("release_locker"),
                                  buttonId: 0,
                                },
                                { buttonName: t("cancel"), buttonId: 1 },
                              ],
                            });
                            if ((await selectedbuttonId) === 0) {
                              let lockerToRemoveParams = {
                                lockersModuleId: row.lockersModuleId,
                                acWorkerId: row.acWorkerId,
                                lockerId: row.lockerRelayNumber,
                              };

                              setLockerToRemove(lockerToRemoveParams);
                            }
                          }}
                        />
                      </Button>
                    </>
                  );
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default LockersContent;
