import "./holiday.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import useFetchGet from "../../../../hooks/fetchHooks/get/useFetchGet";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "../../../helpersComponents/Button/button";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useFetchPostFormData from "../../../../hooks/fetchHooks/post/useFetchPostFormData";
import useFetchPutFormData from "../../../../hooks/fetchHooks/put/useFetchPutFormData";
import {
  AutocompleteStyled,
  TextFieldStyled,
  DatePickerStyled,
  CheckboxStyled,
} from "../../../helpersComponents/MaterialUi/index";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import HolidayLimitBrief from "../../../helpersComponents/HolidayLimitBrief/holidayLimitBrief";
import HolidayLeftInfo from "./holidayLeftInfo";
import { selectAuthUser } from "../../../../reducers/session";
import { ProfileType } from "../../../../enums/profileType";
import useFetchAndSetGET from "./../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { settingIfHoursRequired } from "./settingIfHoursRequired";
import { HolidayRequestTypeFunction } from "../../../../enums/holidayRequestTypeFunction";

const AddEditHolidayRequestPopup: React.FC<any> = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
  timeWorkerId,
  defaultDay,
}: any) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const [selectedEmployeeFormError, setSelectedEmployeeFormError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });

  const [dateError, setDateError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [minDateMessage, setMinDateMessage] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [typeOfRequestFormError, setTypeOfRequestFormError] = useState<any>({
    isError: false,
    errorMessage: "",
  });
  const [substitutionFormError, setSubstitutionFormError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [homeOfficeAddressFormError, setHomeOfficeAddressFormError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });

  const history: any = useHistory();
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(
    history.location.state?.startDate
      ? history.location.state.startDate
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    history.location.state?.endDate
      ? history.location.state.endDate
      : new Date()
  );

  useEffect(() => {
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [oldFiles, setOldFiles] = useState<any>([[], []]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [fromTimeCheckbox, setFromTimeCheckbox] = useState("00:00");
  const [tillHourCheckbox, setTillHourCheckbox] = useState("00:00");
  const [typeOfRequest, setTypeOfRequest] = useState<any>(null);
  const [substitution, setSubstitution] = useState<any>();
  const [homeOfficeAddress, setHomeOfficeAddress] = useState<any>();
  const [ifLoadDialog, setIfLoadDialog] = useState<boolean>(true);

  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);

  const timeWorkers = useCachedFetch(
    "timeWorkersWorkerTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );

  const [ifHoursRequired, setIfHoursRequired] = useState<{
    [key in number]: "1";
  }>({
    4: settings.WebWniosekUrlopowyWyborGodzinyDlaOpiekiWymagany?.value,
  });

  const [maxAttachmentsError, setMaxAttachmentsError] =
    useState<boolean>(false);

  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();

  let minDate: Date | undefined = undefined;
  if (
    settings.WebWniosekUrlopowyLiczbaDniWstecz?.value &&
    settings.WebWniosekUrlopowyLiczbaDniWstecz?.value !== undefined
  ) {
    if (row.hasOwnProperty("id")) {
      minDate = new Date(row.createdAt);
      minDate.setDate(
        minDate.getDate() -
          parseInt(settings.WebWniosekUrlopowyLiczbaDniWstecz.value)
      );
    } else {
      minDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() -
          parseInt(settings.WebWniosekUrlopowyLiczbaDniWstecz.value)
      );
    }
    minDate.setHours(0, 0, 0, 0);
  }

  const [comment, setComment] = useState<any>("");
  const [ifFetchholidayById, setIfFetchholidayById] = useState(false);
  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [checkbox, setCheckbox] = React.useState({
    fromTimeCheckbox: false,
    tillHourCheckbox: false,
  });
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;

  const [requestsTypes] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/holiday-requests-types`
        : `worker-time/${authUserId}/holiday-requests-types`,
    startFetchOnInitial: true,
  });

  const [homeOfficeAddresses, , fetchingAgainHomeOfficeAddresses] =
    useFetchAndSetGET({
      path:
        profileType === ProfileType.SUPERIOR_TIME
          ? `superior-time/home-office-addresses?timeWorkerId=${
              selectedEmployee ? selectedEmployee.id : -1
            }`
          : `worker-time/${authUserId}/home-office-addresses`,
      startFetchOnInitial: false,
    });

  useEffect(() => {
    if (
      profileType === ProfileType.WORKER_TIME ||
      (profileType === ProfileType.SUPERIOR_TIME && selectedEmployee?.id > 0)
    ) {
      setHomeOfficeAddress(null);
      fetchingAgainHomeOfficeAddresses();
    }
  }, [
    selectedEmployee?.id,
    fetchingAgainHomeOfficeAddresses,
    setHomeOfficeAddress,
    profileType,
  ]);

  let postRequest = useFetchPostFormData(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/holiday-requests`
      : `worker-time/${authUserId}/holiday-requests`,
    postRequestBody,
    ifClearReturnedValue
  );

  let putRequest = useFetchPutFormData(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/holiday-requests/${row.id}`
      : `worker-time/${authUserId}/holiday-requests/${row.id}`,
    putRequestBody,
    ifClearReturnedValue
  );

  const [isError, setIsError] = useState<boolean>(false);
  const holidayByIdResponse = useFetchGet(
    profileType === ProfileType.SUPERIOR_TIME
      ? `superior-time/holiday-requests/` + row.id
      : `worker-time/${authUserId}/holiday-requests/` + row.id,
    ifFetchholidayById && row.id ? true : false,
    ifClearReturnedValue
  );

  useEffect(() => {
    if (requestsTypes !== null && requestsTypes.length > 0) {
      if (history.location.state?.requestTypeFunction) {
        let foundElement = requestsTypes.find(
          (el) => el.function === history.location.state.requestTypeFunction
        );
        if (foundElement) {
          setTypeOfRequest(foundElement);
        } else {
          setTypeOfRequest(requestsTypes[0]);
        }
      } else {
        setTypeOfRequest(requestsTypes[0]);
      }

      settingIfHoursRequired(
        settings.WebWnioskiBezGodzin,
        setIfHoursRequired,
        requestsTypes
      );
    }
  }, [requestsTypes, settings, history.location.state?.requestTypeFunction]);

  const handleChangeCheckbox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (row.hasOwnProperty("id")) {
      setIfLoadDialog(true);
      setIfFetchholidayById(true);
    }
    if (holidayByIdResponse.error !== null) {
      setIsError(true);
      setIfFetchholidayById(false);
      return;
    }
    if (timeWorkers === "error") {
      setIsError(true);
      setIfCheckForUpdateCache(false);
      return;
    }

    if (requestsTypes === null || timeWorkers === null) return;

    if (timeWorkerId) {
      setSelectedEmployee(timeWorkers.find((v) => v.id === timeWorkerId));
    }

    if (!row.hasOwnProperty("id")) setIfLoadDialog(false);

    if (holidayByIdResponse.data !== null && row.hasOwnProperty("id")) {
      setSelectedEmployee(
        timeWorkers.find((v) => v.id === holidayByIdResponse.data.timeWorkerId)
      );

      let requestType = requestsTypes.find(
        (v) => v.id === holidayByIdResponse.data.typeId
      );
      setTypeOfRequest(requestType);

      setSubstitution(
        timeWorkers.find((v) => v.id === holidayByIdResponse.data.alternateId)
      );

      if (
        homeOfficeAddresses &&
        (requestType.function === HolidayRequestTypeFunction.HOME_OFFICE ||
          requestType.function ===
            HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE)
      ) {
        setHomeOfficeAddress(
          homeOfficeAddresses.find(
            (v) => v.id === holidayByIdResponse.data.homeOfficeAddressId
          )
        );
      }

      setStartDate(new Date(holidayByIdResponse.data.dateFrom));
      setEndDate(new Date(holidayByIdResponse.data.dateTo));
      setOldFiles([holidayByIdResponse.data.attachments, []]);
      setFromTimeCheckbox(
        new Date(holidayByIdResponse.data.dateFrom).toLocaleString("pl-PL", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      setTillHourCheckbox(
        new Date(holidayByIdResponse.data.dateTo).toLocaleString("pl-PL", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );

      setComment(holidayByIdResponse.data.comment);
      setIfLoadDialog(false);
    }
  }, [
    row,
    holidayByIdResponse,
    requestsTypes,
    timeWorkers,
    timeWorkerId,
    t,
    homeOfficeAddresses,
  ]);

  const [typeOfRequestStatment, setTypeOfRequestStatment] = useState<any>(null);
  useEffect(() => {
    if (typeOfRequest?.id === 4) {
      setTypeOfRequestStatment(<div>{t("type_of_request_id_4")}</div>);
    } else if (typeOfRequest?.nameAbbrev === "UO") {
      setTypeOfRequestStatment(
        <div>
          {t("type_of_request_nameAbbrev_UO_day_1")}
          <br />
          {t("type_of_request_nameAbbrev_UO_day_2")}
        </div>
      );
    } else {
      setTypeOfRequestStatment(null);
    }
  }, [typeOfRequest, t]);

  useEffect(() => {
    if (defaultDay) {
      setStartDate(new Date(defaultDay));
      setEndDate(new Date(defaultDay));
    }
  }, [defaultDay]);

  const applyChanges = useRef(onApplyChanges);
  useEffect(() => {
    if (postRequest.data?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (putRequest.data?.status === 200) {
      applyChanges.current();
      setPutingPostingCircularProgress(false);
    } else if (postRequest.data?.status === 400) {
      let error = "";
      Object.keys(postRequest.data.errors).forEach((errorObject) => {
        postRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (putRequest.data?.status === 400) {
      let error = "";
      Object.keys(putRequest.data.errors).forEach((errorObject) => {
        putRequest.data.errors[errorObject].forEach((errorString) => {
          error += t(errorString) + " ";
        });
      });
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (putRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    } else if (postRequest.error !== null) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [postRequest, putRequest, t]);

  let isHomeOfficeRequestTypeSelected = useCallback(() => {
    if (
      typeOfRequest?.function === HolidayRequestTypeFunction.HOME_OFFICE ||
      typeOfRequest?.function ===
        HolidayRequestTypeFunction.OCCASIONAL_HOME_OFFICE
    ) {
      return true;
    }

    return false;
  }, [typeOfRequest]);

  const confirm = () => {
    let ifFetch = true;
    let dateFrom = computeDate(startDate, "T" + fromTimeCheckbox);
    let dateTo = computeDate(endDate, "T" + tillHourCheckbox);

    let attachmentsAmount = selectedFiles.length + oldFiles[0].length;

    // check max days back
    if (
      settings.WebWniosekUrlopowyLiczbaDniWstecz?.value !== "0" &&
      settings.WebWniosekUrlopowyLiczbaDniWstecz?.value !== undefined &&
      minDate
    ) {
      if (startDate < minDate) {
        setMinDateMessage({
          isError: true,
          errorMessage:
            t("max_date_back") +
            ": " +
            settings.WebWniosekUrlopowyLiczbaDniWstecz?.value +
            " " +
            t("days"),
        });
        ifFetch = false;
      } else {
        setMinDateMessage({
          isError: false,
          errorMessage: "",
        });
      }
    }

    // check max attachments
    if (settings.WebMaksIloscZalWnioskowUrlopowych?.value !== undefined) {
      if (
        attachmentsAmount >
        parseInt(settings.WebMaksIloscZalWnioskowUrlopowych.value)
      ) {
        setMaxAttachmentsError(true);
        ifFetch = false;
      } else {
        setMaxAttachmentsError(false);
      }
    }

    // check if user selected request type
    if (!typeOfRequest) {
      setTypeOfRequestFormError({
        isError: true,
        errorMessage: t("select_request_type"),
      });
      ifFetch = false;
    } else {
      setTypeOfRequestFormError({
        isError: false,
        errorMessage: "",
      });
    }

    if (profileType === ProfileType.SUPERIOR_TIME) {
      if (!selectedEmployee) {
        setSelectedEmployeeFormError({
          isError: true,
          errorMessage: t("select_employee"),
        });
        ifFetch = false;
      } else {
        setSelectedEmployeeFormError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    // check if Substitutions is required
    if (
      !isHomeOfficeRequestTypeSelected() &&
      settings.WebWniosekUrlopowyWymuszanieZastepstwa?.value === "1"
    ) {
      if (!substitution) {
        setSubstitutionFormError({
          isError: true,
          errorMessage: t("select_substitution"),
        });
        ifFetch = false;
      } else {
        setSubstitutionFormError({
          isError: false,
          errorMessage: "",
        });
      }
    } else {
      setSubstitutionFormError({
        isError: false,
        errorMessage: "",
      });

      if (isHomeOfficeRequestTypeSelected() && !homeOfficeAddress) {
        setHomeOfficeAddressFormError({
          isError: true,
          errorMessage: t("select_homeOfficeAddress"),
        });
        ifFetch = false;
      } else {
        setHomeOfficeAddressFormError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    // check if data is valid
    if (startDate === null || endDate === null) {
      setDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    } else if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      setDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
      ifFetch = false;
    } else if (startDate.getTime() > endDate.getTime()) {
      setDateError({
        isError: true,
        errorMessage: t("wrong_date_range"),
      });
      ifFetch = false;
    } else if (startDate.getTime() > endDate.getTime()) {
      setDateError({
        isError: true,
        errorMessage: t("wrong_date_range"),
      });
      ifFetch = false;
    } else {
      setDateError({
        isError: false,
        errorMessage: "",
      });
    }

    setResponseErrorMessage("");

    if (!ifFetch) return;

    setPutingPostingCircularProgress(true);

    if (row.hasOwnProperty("id")) {
      let formdata: any = new FormData();
      formdata.append(
        "requestData",
        JSON.stringify({
          timeWorkerId:
            profileType === ProfileType.SUPERIOR_TIME
              ? selectedEmployee?.id
              : null,
          id: row.id,
          dateFrom,
          dateTo,
          status: row.status,
          typeId: typeOfRequest.id,
          alternateId: substitution ? substitution.id : null,
          homeOfficeAddressId: isHomeOfficeRequestTypeSelected()
            ? homeOfficeAddress
              ? homeOfficeAddress.id
              : null
            : null,
          comment,
        })
      );
      formdata.append("removedAttachments", "[" + oldFiles[1] + "]");
      selectedFiles.forEach((file) => {
        formdata.append("attachments", file);
      });

      setPutRequestBody(formdata);
      // ifLoadDialog = false
    } else {
      let formdata: any = new FormData();
      formdata.append(
        "requestData",
        JSON.stringify({
          timeWorkerId:
            profileType === ProfileType.SUPERIOR_TIME
              ? selectedEmployee?.id
              : null,
          dateFrom,
          dateTo,
          typeId: typeOfRequest.id,
          alternateId: substitution ? substitution.id : null,
          homeOfficeAddressId: isHomeOfficeRequestTypeSelected()
            ? homeOfficeAddress
              ? homeOfficeAddress.id
              : null
            : null,
          comment,
        })
      );
      selectedFiles.forEach((file) => {
        formdata.append("attachments", file);
      });
      setPostRequestBody(formdata);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setIfFetchholidayById(false);

          if (requestsTypes?.[0]) setTypeOfRequest(requestsTypes[0]);

          setSelectedEmployee(null);
          setSubstitution(null);
          setStartDate(new Date());
          setEndDate(new Date());
          setCheckbox({
            fromTimeCheckbox: false,
            tillHourCheckbox: false,
          });
          setMinDateMessage({
            isError: false,
            errorMessage: "",
          });
          setIsError(false);
          setIfLoadDialog(true);
          setFromTimeCheckbox("00:00");
          setTillHourCheckbox("00:00");
          setSelectedFiles([]);
          setFileInputKey(Date.now());
          setComment("");
          setPostRequestBody(false);
          setTypeOfRequestFormError({
            isError: false,
            errorMessage: "",
          });

          setSelectedEmployeeFormError({
            isError: false,
            errorMessage: "",
          });

          setSubstitutionFormError({
            isError: false,
            errorMessage: "",
          });
          setDateError({
            isError: false,
            errorMessage: "",
          });
          setIfClearReturnedValue(true);
          setOldFiles([[], []]);
          setMaxAttachmentsError(false);
          setResponseErrorMessage("");
          setPutingPostingCircularProgress(false);
          setIfCheckForUpdateCache(false);
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
          setIfCheckForUpdateCache(true);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("holiday_request")}</div>
        <div className="description">{t("adding_an_application")}</div>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={ifLoadDialog}
          isError={isError}
          setIfFetchAgain={(fetchAgain) => {
            setIfFetchholidayById(fetchAgain);
            setIfCheckForUpdateCache(fetchAgain);
          }}
          setIsError={setIsError}
          bigSize={true}
        >
          {!ifLoadDialog && !isError && (
            <div className="popupContent">
              {profileType === ProfileType.SUPERIOR_TIME &&
                selectedEmployee && (
                  <div>
                    <HolidayLimitBrief
                      year={new Date(endDate).getFullYear()}
                      size={"small"}
                      hideTop={true}
                      timeWorkerId={selectedEmployee?.id}
                      selectedRequestType={typeOfRequest?.id}
                    />
                  </div>
                )}
              {profileType === ProfileType.WORKER_TIME && (
                <div>
                  <HolidayLimitBrief
                    year={new Date(endDate).getFullYear()}
                    size={"small"}
                    hideTop={true}
                    selectedRequestType={typeOfRequest?.id}
                  />
                </div>
              )}

              {profileType === ProfileType.SUPERIOR_TIME && (
                <div className="selectEmployee">
                  <div className="titleWidthHoliday">{t("employee")}:</div>
                  <div className="inputWidthHoliday">
                    <AutocompleteStyled
                      id="combo-box-demo1"
                      options={timeWorkers}
                      getOptionLabel={(option: any) =>
                        option.firstName + " " + option.lastName
                      }
                      width={"100%"}
                      isError={selectedEmployeeFormError.isError}
                      value={selectedEmployee ? selectedEmployee : null}
                      onChange={(_, newValue) => {
                        setSelectedEmployee(newValue);
                      }}
                      label={t("employee")}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedEmployeeFormError({
                            isError: true,
                            errorMessage: t("select_employee"),
                          });
                        } else {
                          setSelectedEmployeeFormError({
                            isError: false,
                            errorMessage: t(""),
                          });
                        }
                      }}
                    />
                    {selectedEmployeeFormError.isError ? (
                      <div className="errorHolidayrequest">
                        {selectedEmployeeFormError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="typeOfRequest">
                <div className="titleWidthHoliday">{t("request_type")}:</div>
                <div className="inputWidthHoliday">
                  <AutocompleteStyled
                    id="combo-box-demo1"
                    options={requestsTypes}
                    getOptionLabel={(option: any) => {
                      return "[" + option.nameAbbrev + "] " + option.name;
                    }}
                    width={"100%"}
                    isError={typeOfRequestFormError.isError}
                    value={typeOfRequest ? typeOfRequest : null}
                    onChange={(_, newValue) => {
                      setTypeOfRequest(newValue);
                    }}
                    label={t("request_type")}
                    required={true}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setTypeOfRequestFormError({
                          isError: true,
                          errorMessage: t("select_request_type"),
                        });
                      } else {
                        setTypeOfRequestFormError({
                          isError: false,
                          errorMessage: t(""),
                        });
                      }
                    }}
                  />
                  {typeOfRequestFormError.isError ? (
                    <div className="errorHolidayrequest">
                      {typeOfRequestFormError.errorMessage}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="typeOfRequestStatment">
                {typeOfRequestStatment}
              </div>
              <div className="selectDateHolidayPopup">
                <div>
                  <div className="titleWidthHoliday">{t("date_from")}:</div>
                  <div className="inputWidthHoliday">
                    <DatePickerStyled
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      date={startDate}
                      setDate={setStartDate}
                      minDate={minDate}
                      isError={minDateMessage.isError || dateError.isError}
                      // minDateMessage={t("max_date_back") + ": " + settings.WebWniosekUrlopowyLiczbaDniWstecz?.value + " " + t("days")}
                    />
                    {minDateMessage.isError ? (
                      <div className="errorHolidayrequest">
                        {minDateMessage.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className="titleWidthHoliday">{t("date_to")}:</div>
                  <div className="inputWidthHoliday">
                    <DatePickerStyled
                      view={["year", "month", "date"]}
                      format="dd-MM-yyyy"
                      date={endDate}
                      setDate={setEndDate}
                      width={"100%"}
                      isError={dateError.isError}
                    />
                    {dateError.isError ? (
                      <div className="errorHolidayrequest">
                        {dateError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {(
                typeOfRequest !== null
                  ? ifHoursRequired[typeOfRequest.id] === "1"
                  : false
              ) ? (
                <div className="selectTime">
                  <div>
                    <div>
                      <FormControlLabel
                        control={
                          <CheckboxStyled
                            checked={checkbox.fromTimeCheckbox}
                            name="fromTimeCheckbox"
                            onChange={handleChangeCheckbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={t("from_time")}
                      />
                    </div>
                    <div>
                      {checkbox.fromTimeCheckbox ? (
                        <TextFieldStyled
                          hiddenLabel
                          width={100}
                          value={fromTimeCheckbox}
                          onChange={(e) => {
                            setFromTimeCheckbox(e.target.value);
                          }}
                          type="time"
                        />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <div>
                      <FormControlLabel
                        control={
                          <CheckboxStyled
                            checked={checkbox.tillHourCheckbox}
                            name="tillHourCheckbox"
                            onChange={handleChangeCheckbox}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={t("till_hour")}
                      />
                    </div>
                    <div>
                      {checkbox.tillHourCheckbox ? (
                        <TextFieldStyled
                          hiddenLabel
                          width={100}
                          value={tillHourCheckbox}
                          onChange={(e) => {
                            setTillHourCheckbox(e.target.value);
                          }}
                          type="time"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              {profileType === ProfileType.SUPERIOR_TIME &&
                selectedEmployee && (
                  <HolidayLeftInfo
                    startDate={startDate}
                    endDate={endDate}
                    open={open}
                    timeWorkerId={selectedEmployee.id}
                  />
                )}
              {profileType === ProfileType.WORKER_TIME && (
                <HolidayLeftInfo
                  startDate={startDate}
                  endDate={endDate}
                  open={open}
                />
              )}

              {!isHomeOfficeRequestTypeSelected() &&
                typeOfRequest?.function !==
                  HolidayRequestTypeFunction.BACKOFF_DAYS_FROM_HOME_OFFICE_REQUEST && (
                  <div className="substitution">
                    <div className="titleWidthHoliday">
                      {t("substitution")}:
                    </div>
                    <div className="inputWidthHoliday">
                      <AutocompleteStyled
                        id="combo-box-demo"
                        options={timeWorkers}
                        getOptionLabel={(option: any) =>
                          option.firstName + " " + option.lastName
                        }
                        value={substitution ? substitution : null}
                        onChange={(_, newValue) => {
                          setSubstitution(newValue);
                        }}
                        required={
                          settings.WebWniosekUrlopowyWymuszanieZastepstwa
                            ?.value === "1"
                        }
                        onBlur={(e) => {
                          if (
                            settings.WebWniosekUrlopowyWymuszanieZastepstwa
                              ?.value !== "1"
                          )
                            return;
                          if (e.target.value === "") {
                            setSubstitutionFormError({
                              isError: true,
                              errorMessage: t("select_substitution"),
                            });
                          } else {
                            setSubstitutionFormError({
                              isError: false,
                              errorMessage: t(""),
                            });
                          }
                        }}
                        isError={substitutionFormError.isError}
                        label={t("substitution")}
                        width={"100%"}
                      />
                      {substitutionFormError.isError ? (
                        <div className="errorHolidayrequest">
                          {substitutionFormError.errorMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

              {isHomeOfficeRequestTypeSelected() && (
                <div className="homeOfficeAddress">
                  <div className="titleWidthHoliday">
                    {t("homeOfficeAddress")}:
                  </div>
                  <div className="inputWidthHoliday">
                    <AutocompleteStyled
                      id="combo-box-demo"
                      options={homeOfficeAddresses ? homeOfficeAddresses : []}
                      getOptionLabel={(option: any) =>
                        `${option.street} ${option.city}`
                      }
                      value={homeOfficeAddress ? homeOfficeAddress : null}
                      onChange={(_, newValue) => {
                        setHomeOfficeAddress(newValue);
                      }}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setHomeOfficeAddressFormError({
                            isError: true,
                            errorMessage: t("select_homeOfficeAddress"),
                          });
                        } else {
                          setHomeOfficeAddressFormError({
                            isError: false,
                            errorMessage: t(""),
                          });
                        }
                      }}
                      isError={homeOfficeAddressFormError.isError}
                      label={t("homeOfficeAddress")}
                      width={"100%"}
                    />
                    {homeOfficeAddressFormError.isError ? (
                      <div className="errorHolidayrequest">
                        {homeOfficeAddressFormError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="comment">
                <div className="titleWidthHoliday">{t("comment")}:</div>
                <div className="inputWidthHoliday">
                  <TextFieldStyled
                    defaultValue={comment}
                    label={t("comment")}
                    onBlur={(e) => {
                      setComment(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 50);
                    }}
                    multiline
                    width={"100%"}
                  />
                </div>
              </div>
              {settings.WebWylaczObslugeZalWnioskowUrlopowych?.value !== "1" ? (
                <>
                  <div className="attachments">
                    <div>{t("new_attachments")}:</div>
                    <div className="inputSelectFile">
                      <input
                        type="file"
                        className="inputFile"
                        multiple
                        accept={
                          settings.WebObslugiwaneTypyZalWnioskowUrlopowych
                            ?.value
                        }
                        key={fileInputKey}
                        onChange={(e) => {
                          if (!e.target.files) return;
                          let files: any = [];
                          Array.from(e.target.files).forEach((file) => {
                            files.push(file);
                          });

                          setSelectedFiles(files);
                        }}
                      />
                    </div>
                  </div>
                  <div className="files">
                    {selectedFiles.map((file, index) => {
                      return (
                        <div className="singleFiles" key={index}>
                          <div>
                            <b>{file.name}</b> {(file.size * 0.001).toFixed(2)}{" "}
                            kB
                          </div>
                          <div
                            onClick={() => {
                              let cancelFile = selectedFiles.findIndex(
                                (cancelFile) => cancelFile.name === file.name
                              );
                              let newFiles = [...selectedFiles];
                              newFiles.splice(cancelFile, 1);
                              setSelectedFiles(newFiles);
                              setFileInputKey(Date.now());
                            }}
                          >
                            <Button>{t("delete")}</Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="attachments">
                    <div>{t("old_attachments")}:</div>
                  </div>
                  <div className="files">
                    {oldFiles[0].map((file, index) => {
                      return (
                        <div className="singleFiles" key={index}>
                          <div>
                            <b>{file.fileName}</b>
                            {/* {(file.size * 0.001).toFixed(2)} kB */}
                          </div>
                          <div
                            onClick={() => {
                              let indexFileToDelete = oldFiles[0].findIndex(
                                (cancelFile) =>
                                  cancelFile.fileName === file.fileName
                              );
                              let currentFiles = [...oldFiles[0]];
                              let deletedFiles = [...oldFiles[1]];

                              let deleteFile = currentFiles.splice(
                                indexFileToDelete,
                                1
                              );
                              deletedFiles.push(deleteFile[0].id);

                              setOldFiles([currentFiles, deletedFiles]);
                            }}
                          >
                            <Button>{t("delete")}</Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {maxAttachmentsError ? (
                    <div className="errorHolidayrequest">
                      {/* {t("select_request_type")} */}
                      {t("max_amount_attachmenst")}:{" "}
                      {settings.WebMaksIloscZalWnioskowUrlopowych.value}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          )}
        </LoadingWrapper>

        {/* {absenceTypesFetched.error ? absenceTypesFetched.error.title : null} */}
      </DialogContent>
      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditHolidayRequestPopup;
