import { ProfileType } from "../enums/profileType";

export function getProfileName(profileType: ProfileType): string {
  switch (profileType) {
    case ProfileType.SUPERIOR_AC:
      return "superior-ac";

    case ProfileType.SUPERIOR_TIME:
      return "superior-time";

    case ProfileType.WORKER_AC:
      return "worker-ac";

    case ProfileType.WORKER_TIME:
      return "worker-time";

    case ProfileType.ADMINISTRATOR_AC:
      return "administrator-ac";

    case ProfileType.AGENT:
      return "agent";

    case ProfileType.RECEPTION:
      return "reception";

    case ProfileType.GUARD:
      return "guard";

    case ProfileType.PFSO:
      return "pfso";

    case ProfileType.SHIFT_DIRECTOR:
      return "shift-director";

    case ProfileType.SECURITY:
      return "security";

    case ProfileType.EMPLOYEE:
      return "employee";

    case ProfileType.OHS:
      return "ohs";

    case ProfileType.OPERATIONAL_SUPPORT:
      return "operational-supporty";

    case ProfileType.INTEGRATOR:
      return "integrator";

    case ProfileType.EVENTS_PREVIEW:
      return "events-preview";

    case ProfileType.LOCKERS_MANAGEMENT:
      return "lockers-management";
  }
}
