import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import FormAddNewCompany from "../../../agent/FromAddNewCompany/FormAddNewCompany";
import { TextFieldFormik } from "../../FormikInputs";
import AutocompleteFormik from "../../FormikInputs/Autocomplete/autocomplete";
import DatePickerFormik from "../../FormikInputs/DatePicker/datePicker";
import GetCardTagPopupRow from "../../GetCardTagPopupRow/getCardTagPopupRow";
import {
  Row,
  RowInput,
  RowTitle,
  TwoColumnsRowsContainer,
} from "../../PopupHelpers";
import ReadIdCardNumberFormikPopupRow from "../../ReadIdCardNumberFormikPopupRow/readIdCardNumberFormikPopupRow";
import * as Yup from "yup";
import styles from "./contentOperationalSupportNotificationStyles.module.scss";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import ButtonStyled from "../../Button/button";
import { CircularProgress } from "@material-ui/core";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import getInitialState from "./initialState";
import yupIsValidDate from "../../../../HelpersFunctions/yupSchemes/isValidDate";
import moment from "moment";
import { TypeOfCompanyInPorts } from "../../../../enums/typeOfCompanyInPorts";
import FormAddNewCompanyPosition from "../../CommonForms/AddNewCompanyPosition/FormAddNewCompanyPosition";
import LoadingWrapper from "../../LoadingWrapper/loadingWrapper";
import { timeStringToMinutes } from "../../../../HelpersFunctions/dateAndTime/timeStrings";
import { useHistory } from "react-router-dom";

const IDReaderComponent = ({
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const getIdCardCallback = useCallback(
    (readCardData) => {
      setFieldValue("firstName", readCardData.fields.firstName);
      setFieldValue("lastName", readCardData.fields.lastName);
    },
    [setFieldValue]
  );

  return (
    <ReadIdCardNumberFormikPopupRow
      widthTitle={180}
      successCallback={getIdCardCallback}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      setFieldTouched={setFieldTouched}
      name={`idNumber`}
      required={true}
      onChangeExtra={(e) => {
        setFieldValue(`idNumber`, e.target.value);
      }}
    />
  );
};

const ContentOperationalSupportNotificationInPorts = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { confirm } = useConfirm();
  const [resetForm, setResetForm] = useState<() => void>();

  const validationSchemaObj = Yup.object().shape({
    company: Yup.object().nullable().required(t("field_required")),
    entryDate: yupIsValidDate({ t }),
    firstName: Yup.string().required(t("field_required")),
    lastName: Yup.string().required(t("field_required")),
    position: Yup.object().nullable().required(t("field_required")),
    permissionZone: Yup.object().nullable().required(t("field_required")),
    timeFrom: Yup.string()
      .nullable()
      .required(t("field_required"))
      .test("is-time", t("invalid_time_format"), (value) => {
        return moment(value, "HH:mm", true).isValid();
      }),
    timeTo: Yup.string()
      .nullable()
      .required(t("field_required"))
      .test("is-time", t("invalid_time_format"), (value) => {
        return moment(value, "HH:mm", true).isValid();
      }),

    cardNumber: Yup.string().required(t("field_required")),
    idNumber: Yup.string().required(t("field_required")),
    comments: Yup.string().required(t("field_required")),
  });
  const initState = getInitialState({ t });

  let successCallback = useCallback(
    (response: UseFetchResponse) => {
      if (resetForm === undefined) return;
      resetForm();
    },
    [resetForm]
  );

  const [bodyRequest, setBodyRequest] = useState<false | any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: "reception/lists-of-employees",
    method: "POST",
    contentType: "application/json",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (bodyRequest && resetForm !== undefined) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData, resetForm]);

  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | null | undefined
  >(0);

  const [companies, fetchingStateCompanies, fetchAgainCompanies] =
    useFetchAndSetGET<ICompanyInPorts[]>({
      path: "reception/companies-in-ports?typeOfCompany=OPERATIONAL_SUPPORT",
    });

  const [
    companyPositions,
    fetchingStateCompanyPositions,
    fetchAgainCompanyPositions,
  ] = useFetchAndSetGET<ICompanyInPorts[]>({
    path: `reception/companies-positions-in-ports?companyId=${selectedCompanyId}`,
  });

  const [
    permissionZones,
    fetchingStatePermissionZones,
    fetchAgainPermissionZones,
  ] = useFetchAndSetGET<PermissionZone[]>({
    path: "reception/permission-zones?isActive=true",
    //startFetchOnInitial: permissionZoneId === null ? true : false,
  });

  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [showAddCompanyPositionForm, setShowAddCompanyPositionForm] =
    useState(false);
  const [addedCompanyId, setAddedCompanyId] = useState<false | number>(false);
  const [addedCompanyPositionId, setAddedCompanyPositionId] = useState<
    false | number
  >(false);

  useEffect(() => {
    if (addedCompanyId !== false) {
      fetchAgainCompanies();
    }
  }, [addedCompanyId, fetchAgainCompanies]);

  useEffect(() => {
    if (addedCompanyPositionId !== false) {
      fetchAgainCompanyPositions();
    }
  }, [addedCompanyPositionId, fetchAgainCompanyPositions]);

  useEffect(() => {
    if (selectedCompanyId) {
      fetchAgainCompanyPositions();
    }
  }, [selectedCompanyId, fetchAgainCompanyPositions]);

  useEffect(() => {
    if (
      !fetchingStatePostData.response?.isError &&
      fetchingStatePostData.response?.status === 201
    ) {
      history.goBack();
    }
  }, [fetchingStatePostData.response, history]);

  useEffect(() => {
    if (addedCompanyId) {
      setSelectedCompanyId(addedCompanyId);
    }
  }, [addedCompanyId]);

  return (
    <div className="flexAndCenter">
      <Formik
        validationSchema={validationSchemaObj}
        initialValues={initState}
        onSubmit={(values, formikHelpers) => {
          let notificationToAdd = {
            date: values.entryDate,
            timeFromInMinutes: timeStringToMinutes(values.timeFrom),
            timeToInMinutes: timeStringToMinutes(values.timeTo),
            employess: [
              {
                permissionZoneId: values.permissionZone?.id,
                cardNumber: values.cardNumber,
                employeeFirstName: values.firstName,
                employeeLastName: values.lastName,
                employeeIDNumber: values.idNumber,
                company: values.company?.name,
                position: values.position?.name,
                comments: values.comments,
              },
            ],
          };

          setBodyRequest(JSON.stringify(notificationToAdd));
          setResetForm(() => formikHelpers.resetForm);
        }}
      >
        {({
          values,
          resetForm,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          errors,
        }) => {
          return (
            <Form noValidate>
              <LoadingWrapper
                isLodadingProgress={
                  fetchingStateCompanies.isFetching &&
                  fetchingStateCompanyPositions.isFetching &&
                  fetchingStatePermissionZones.isFetching
                }
                isError={
                  fetchingStateCompanies.isError ||
                  fetchingStateCompanyPositions.isError ||
                  fetchingStatePermissionZones.isError
                }
                setIfFetchAgain={() => {
                  if (fetchingStateCompanies.isError) {
                    fetchAgainCompanies();
                  }

                  if (fetchingStateCompanyPositions.isError) {
                    fetchAgainCompanyPositions();
                  }

                  if (fetchingStatePermissionZones.isError) {
                    fetchAgainPermissionZones();
                  }
                }}
              >
                <div className={styles.formContainer}>
                  <div className={styles.companyContainer}>
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("company")}:</RowTitle>
                        <RowInput
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <AutocompleteFormik
                            options={companies}
                            getOptionLabel={(option: ICompanyInPorts) => {
                              return option?.name ? option.name : "";
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: ICompanyInPorts,
                              value: ICompanyInPorts
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_company")}
                            name="company"
                            required={true}
                            style={{ width: "275px", marginRight: "10px" }}
                            onChangeExtra={(_, value) => {
                              if (value && value.id) {
                                setSelectedCompanyId(value.id);
                              } else {
                                setSelectedCompanyId(null);
                                setFieldValue("position", []);
                              }
                            }}
                          />

                          <div
                            className={styles.iconShowCompanyForm}
                            onClick={() => {
                              setShowAddCompanyForm(!showAddCompanyForm);
                            }}
                          >
                            {showAddCompanyForm ? (
                              <FontAwesomeIcon
                                className="faMinusNotifications fa-lg"
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="faPlusNotificationsAgent fa-lg"
                                icon={faPlus}
                              />
                            )}
                          </div>
                        </RowInput>
                      </Row>
                      <Row>
                        <RowTitle>{t("entry_date")}</RowTitle>
                        <RowInput>
                          <DatePickerFormik
                            customLabel={t("date_from")}
                            name="entryDate"
                            view={["year", "month", "date"]}
                            format="dd-MM-yyyy"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    {showAddCompanyForm && (
                      <FormAddNewCompany
                        typeOfCompanyInPorts={
                          TypeOfCompanyInPorts.OPERATIONAL_SUPPORT
                        }
                        addCompanyPath={"reception/companies-in-ports"}
                        setShowAddCompanyForm={setShowAddCompanyForm}
                        setAddedCompanyId={setAddedCompanyId}
                        addedCompanyId={addedCompanyId}
                        companies={companies}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </div>

                  <div className={styles.companyContainer}>
                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("first_name")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            ifToUpperCaseShipsAndPorts={true}
                            label={t("first_name")}
                            name="firstName"
                            type="text"
                            required={true}
                          />
                        </RowInput>
                      </Row>

                      <Row>
                        <RowTitle>{t("last_name")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            ifToUpperCaseShipsAndPorts={true}
                            label={t("last_name")}
                            name="lastName"
                            type="text"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("position")}:</RowTitle>
                        <RowInput
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <AutocompleteFormik
                            options={companyPositions}
                            getOptionLabel={(
                              option: ICompanyPositionInPorts
                            ) => {
                              return option?.name ? option.name : "";
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: ICompanyPositionInPorts,
                              value: ICompanyPositionInPorts
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_position")}
                            name="position"
                            required={true}
                            style={{ width: "275px", marginRight: "10px" }}
                          />

                          <div
                            className={styles.iconShowCompanyForm}
                            onClick={() => {
                              setShowAddCompanyPositionForm(
                                !showAddCompanyPositionForm
                              );
                            }}
                          >
                            {showAddCompanyPositionForm ? (
                              <FontAwesomeIcon
                                className="faMinusNotifications fa-lg"
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="faPlusNotificationsAgent fa-lg"
                                icon={faPlus}
                              />
                            )}
                          </div>
                        </RowInput>
                      </Row>
                      <Row>
                        <RowTitle>{t("permission_zone")}:</RowTitle>
                        <RowInput>
                          <AutocompleteFormik
                            options={permissionZones}
                            getOptionLabel={(option: PermissionZone) => {
                              return option.name;
                            }}
                            width="100%"
                            getOptionSelected={(
                              option: PermissionZone,
                              value: PermissionZone
                            ) => {
                              return option.id === value.id;
                            }}
                            label={t("select_permission_zone")}
                            name="permissionZone"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    {showAddCompanyPositionForm && (
                      <FormAddNewCompanyPosition
                        companyId={selectedCompanyId}
                        addCompanyPositionPath={
                          "reception/companies-positions-in-ports"
                        }
                        setShowAddCompanyPositionForm={
                          setShowAddCompanyPositionForm
                        }
                        setAddedCompanyPositionId={setAddedCompanyPositionId}
                        addedCompanyPositionId={addedCompanyPositionId}
                        companyPositions={companyPositions}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setFieldError={setFieldError}
                      />
                    )}

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("hour_from")}:</RowTitle>

                        <RowInput>
                          <TextFieldFormik
                            name="timeFrom"
                            type="time"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <Row>
                        <RowTitle>{t("hour_to")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="timeTo"
                            type="time"
                            required={true}
                          />
                        </RowInput>
                      </Row>
                      <Row>
                        <RowTitle>{t("comments")}:</RowTitle>
                        <RowInput>
                          <TextFieldFormik
                            name="comments"
                            type="text"
                            required={true}
                            disabled
                          />
                        </RowInput>
                      </Row>
                    </TwoColumnsRowsContainer>

                    <TwoColumnsRowsContainer>
                      <GetCardTagPopupRow
                        widthTitle={180}
                        setFieldValue={setFieldValue}
                        name={`cardNumber`}
                        componentId={"cardNumber"}
                        isPopupOpen={true}
                        required={true}
                        mask={"****************"}
                      />

                      <IDReaderComponent
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                      />
                    </TwoColumnsRowsContainer>
                  </div>
                </div>

                <div className={styles.cofnirmCelat}>
                  <div>
                    <ButtonStyled
                      onClick={async () => {
                        let selectedbuttonId = await confirm({
                          text: `${t("reset_form_information")}.`,
                          buttons: [
                            { buttonName: t("clear"), buttonId: 0 },
                            { buttonName: t("cancel"), buttonId: 1 },
                          ],
                        });
                        if ((await selectedbuttonId) === 0) {
                          resetForm({ values: getInitialState({ t }) });
                        }
                      }}
                      type="button"
                    >
                      {t("clear")}
                    </ButtonStyled>
                  </div>
                  <div>
                    {fetchingStatePostData.isFetching ? (
                      <div>
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <ButtonStyled type="submit">{t("confirm")}</ButtonStyled>
                    )}
                  </div>
                </div>
              </LoadingWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContentOperationalSupportNotificationInPorts;
