import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export type DialogType = "confirmation" | "warning";

interface initialStateTypes {
  confirmState: {
    show: boolean;
    text: any;
    buttons: { buttonName: any; buttonId: number }[];
    typeDialog?: DialogType;
    disableBackdropClick?: boolean;
  };
}

interface showConfirmInterface {
  text: any;
  buttons: { buttonName: any; buttonId: number }[];
  typeDialog?: DialogType;
  disableBackdropClick?: boolean;
}

const initialState: initialStateTypes = {
  confirmState: {
    show: false,
    text: "",
    buttons: [],
    typeDialog: "confirmation",
    disableBackdropClick: false,
  },
};

export const confirmStateReducer = createSlice({
  name: "confirmState",
  initialState,
  reducers: {
    showConfirm: (state, action: PayloadAction<showConfirmInterface>) => {
      state.confirmState = {
        show: true,
        text: action.payload.text,
        buttons: action.payload.buttons,
        typeDialog: action.payload.typeDialog,
        disableBackdropClick: action.payload.disableBackdropClick,
      };
    },
    hideConfirm: (state) => {
      state.confirmState = {
        show: false,
        text: state.confirmState.text,
        buttons: [...state.confirmState.buttons],
        typeDialog: state.confirmState.typeDialog,
        disableBackdropClick: state.confirmState.disableBackdropClick,
      };
    },
  },
});

export const selectConfirmState = (state: RootState) =>
  state.useConfirmState.confirmState;
export const { showConfirm } = confirmStateReducer.actions;
export const { hideConfirm } = confirmStateReducer.actions;
export default confirmStateReducer.reducer;
