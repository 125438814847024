import { Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectAuthUser } from "../../../reducers/session";
import { useAppSelector } from "../../../store/hooks";
import { colorNameDictionary } from "../../ProfilesRouting/profilesDictionaries";
import styles from "./styles.module.scss";
import LetInEmployeePopup from "./SupportPopups/LetInEmployeePopup";
import RejectEmployeePopup from "./SupportPopups/RejectEmployeePopup";

const RowDetailSupportListsOfEmployees = ({
  row,
  pathApiLetIn,
  pathApiReject,
  fetchAgainListsOfEmployees,
}: {
  row: IListOfEmployees;
  pathApiLetIn: string;
  pathApiReject: string;
  fetchAgainListsOfEmployees: () => void;
}) => {
  const { t } = useTranslation();
  const employees = row.employess;
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;

  const [isOpenLetInPopup, setOpenLetInPopup] = useState<{
    isOpen: boolean;
    employee: IListOfEmployeesEmployee | null;
  }>({
    isOpen: false,
    employee: null,
  });
  const handleCloseLetInPopup = useCallback(() => {
    setOpenLetInPopup({
      isOpen: false,
      employee: null,
    });
  }, []);

  const [isOpenRejectPopup, setIsOpenRejectPopup] = useState<{
    isOpen: boolean;
    rowId: number | null;
  }>({
    isOpen: false,
    rowId: null,
  });
  const handleCloseRejectPopup = useCallback(() => {
    setIsOpenRejectPopup({
      isOpen: false,
      rowId: null,
    });
  }, []);

  return (
    <div>
      <table className={styles.table}>
        <thead style={{ backgroundColor: colorNameDictionary[authUserType] }}>
          <tr>
            <th className={styles.actionColumn}>{t("actions")}</th>
            <th>Lp</th>
            <th>{t("employee")}</th>
            <th>{t("id_number")}</th>
            <th>{t("company")}</th>
            <th>{t("position")}</th>
            <th>{t("comments")}</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => {
            return (
              <tr
                key={employee.id}
                className={
                  employee.isLetIn === true
                    ? styles.lettedIn
                    : employee.isLetIn === false
                    ? styles.notLettedIn
                    : ""
                }
              >
                <td className={styles.actionColumn}>
                  {employee.isLetIn === null && (
                    <div className={styles.buttonsContainer}>
                      <div>
                        <Button
                          className={styles.buttonLetIn}
                          onClick={() => {
                            setOpenLetInPopup({
                              isOpen: true,
                              employee: employee,
                            });
                          }}
                        >
                          {t("let_in")}
                        </Button>
                      </div>
                      <div>
                        <Button
                          className={styles.buttonReject}
                          onClick={() => {
                            setIsOpenRejectPopup({
                              isOpen: true,
                              rowId: employee.id,
                            });
                          }}
                        >
                          {t("reject")}
                        </Button>
                      </div>
                    </div>
                  )}
                </td>
                <td className={styles.lpColumn}>{++index}</td>
                <td>
                  {employee.employeeFirstName} {employee.employeeLastName}
                </td>
                <td>
                  {employee.employeeIDNumber ? employee.employeeIDNumber : "-"}
                </td>
                <td>{employee.company}</td>
                <td>{employee.position ? employee.position : "-"}</td>
                <td>{employee.comments ? employee.comments : "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div>
        {isOpenLetInPopup.isOpen && (
          <LetInEmployeePopup
            permissionZonesPathApi="reception/permission-zones?isActive=true"
            pathApiLetIn={pathApiLetIn}
            isOpen={isOpenLetInPopup.isOpen}
            employee={isOpenLetInPopup.employee!}
            onCancelChanges={handleCloseLetInPopup}
            successCallback={fetchAgainListsOfEmployees}
          />
        )}
      </div>

      <div>
        {isOpenRejectPopup.isOpen && (
          <RejectEmployeePopup
            patchApi={`${pathApiReject}/${isOpenRejectPopup.rowId}`}
            isOpen={isOpenRejectPopup.isOpen}
            successCallback={fetchAgainListsOfEmployees}
            closePopup={handleCloseRejectPopup}
          />
        )}
      </div>
    </div>
  );
};

export default RowDetailSupportListsOfEmployees;
