import { useState, useEffect, useRef } from "react";
import Button from "../../../helpersComponents/Button/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import computeDate from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import "./hoursRequest.scss";
import {
  AutocompleteStyled,
  DatePickerStyled,
  TextFieldStyled,
} from "../../../helpersComponents/MaterialUi/index";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { useHistory } from "react-router-dom";
import TopRows from "./popupTopRows";
import { selectAuthUser } from "../../../../reducers/session";
import { ProfileType } from "../../../../enums/profileType";
import useCachedFetch from "../../../../hooks/useCachedFetch/cachedFetch";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import HoursRequestContent from "./HoursRequestContent";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import {
  getJoinedRequestsName,
  haveJoinedRequestsCommonDateField,
  isJoinedRequestType,
} from "../../../../HelpersFunctions/requests/hoursRequestsHelpers";
import { HourRequestType } from "../../../../enums/hourRequestType";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";

const AddEditHoursRequestPopup = ({
  row,
  onApplyChanges,
  onCancelChanges,
  open,
  timeWorkerId,
  defaultDay,
}: any) => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const settings = useAppSelector(selectSettings);
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const profileType = useAppSelector(selectAuthUser).currentProfile.type;
  const [ifCheckForUpdateCache, setIfCheckForUpdateCache] =
    useState<boolean>(false);
  const [ifClearReturnedValue, setIfClearReturnedValue] =
    useState<boolean>(false);
  const [putingPostingCircularProgress, setPutingPostingCircularProgress] =
    useState<boolean>(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(
    row.hasOwnProperty("id") ? true : false
  );
  const [isError, setIsError] = useState<boolean>(false);

  const [isLoadingTopRows, setIsLoadingTopRows] = useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [selectedEmployeeFormError, setSelectedEmployeeFormError] =
    useState<FormError>({
      isError: false,
      errorMessage: "",
    });

  const [selectedHoursRequestsType, setSelectedHoursRequestsType] =
    useState<IHourRequestType | null>(null);
  const [selectedHoursRequestsTypeError, setSelectedHoursRequestsTypeError] =
    useState<any>({
      isError: false,
      errorMessage: "",
    });

  const [request1SelectedDate, setRequest1SelectedDate] = useState(
    history.location.state?.startDate
      ? history.location.state.startDate
      : new Date()
  );

  const [request2SelectedDate, setRequest2SelectedDate] = useState(
    history.location.state?.startDate
      ? history.location.state.startDate
      : new Date()
  );

  const [request1SelectedDateObj, setRequest1SelectedDateObj] = useState<
    HourRequestContentDate | undefined
  >(undefined);
  const [request2SelectedDateObj, setRequest2SelectedDateObj] = useState<
    HourRequestContentDate | undefined
  >(undefined);

  const [request1SelectedDateError, setRequest1SelectedDateError] =
    useState<FormError>({ isError: false, errorMessage: "" });

  const [request2SelectedDateError, setRequest2SelectedDateError] =
    useState<FormError>({ isError: false, errorMessage: "" });

  const [request1FromHour, setRequest1FromHour] = useState<string>("00:00");
  const [request1FromHourError, setRequest1FromHourError] = useState<FormError>(
    {
      isError: false,
      errorMessage: "",
    }
  );

  const [request1TillHour, setRequest1TillHour] = useState<string>("00:00");
  const [request1TillHourError, setRequest1TillHourError] = useState<FormError>(
    {
      isError: false,
      errorMessage: "",
    }
  );

  const [request2FromHour, setRequest2FromHour] = useState<string>("00:00");
  const [request2FromHourError, setRequest2FromHourError] = useState<FormError>(
    {
      isError: false,
      errorMessage: "",
    }
  );

  const [request2TillHour, setRequest2TillHour] = useState<string>("00:00");
  const [request2TillHourError, setRequest2TillHourError] = useState<FormError>(
    {
      isError: false,
      errorMessage: "",
    }
  );

  const [expandRequest1, setExpandRequest1] = useState<boolean>(false);
  const [expandRequest2, setExpandRequest2] = useState<boolean>(false);

  const [comment, setComment] = useState<any>("");

  const [commentError, setCommentError] = useState<any>({
    isError: false,
    errorMessage: "",
  });

  const [minDate, setMinDate] = useState<any | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<any | undefined>(undefined);

  const [timeWorker, , fetchAgainTimeWorker] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/time-workers/${selectedEmployee?.id}?fields=dateOfBirthday`
        : `worker-time/${authUserId}/time-workers/${authUserId}?fields=dateOfBirthday`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (selectedEmployee?.id) {
      fetchAgainTimeWorker();
    }
  }, [selectedEmployee?.id, fetchAgainTimeWorker]);

  useEffect(() => {
    if (
      selectedHoursRequestsType &&
      selectedHoursRequestsType.id === 19 &&
      selectedEmployee?.id &&
      timeWorker?.dateOfBirthday
    ) {
      let currentDate = new Date();
      let maxDate = new Date(timeWorker.dateOfBirthday);
      maxDate.setFullYear(currentDate.getFullYear());
      maxDate.setMonth(maxDate.getMonth() + 1);
      maxDate.setHours(23);
      maxDate.setMinutes(59);
      maxDate.setSeconds(59);
      setMaxDate(maxDate);
      return;
    }

    if (
      settings.WnioskiOgraniczoneDoDzis?.value &&
      settings.WnioskiOgraniczoneDoDzis?.value !== "0"
    ) {
      let minimumDate = new Date();
      let maximumDate = new Date();

      if (row.hasOwnProperty("id")) {
        minimumDate = new Date(row.createdAt);
        maximumDate = new Date(row.createdAt);
      }

      minimumDate.setHours(0, 0, 0, 0);
      maximumDate.setHours(23, 59, 59);

      setMinDate(minimumDate);
      setMaxDate(maximumDate);
    } else {
      let minimumDate = new Date();
      let maximumDate = new Date();
      maximumDate.setFullYear(9999);

      if (settings.WebWniosekGodzinowyLiczbaDniWstecz?.value) {
        if (row.hasOwnProperty("id")) {
          minimumDate = new Date(row.createdAt);

          minimumDate.setDate(
            minimumDate.getDate() -
              parseInt(settings.WebWniosekGodzinowyLiczbaDniWstecz.value)
          );
        } else {
          minimumDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() -
              parseInt(settings.WebWniosekGodzinowyLiczbaDniWstecz.value)
          );
        }

        minimumDate.setHours(0, 0, 0, 0);
      }

      setMinDate(minimumDate);
      setMaxDate(maximumDate);
    }
  }, [
    row,
    settings.WebWniosekGodzinowyLiczbaDniWstecz?.value,
    settings.WnioskiOgraniczoneDoDzis?.value,
    selectedHoursRequestsType,
    timeWorker?.dateOfBirthday,
    selectedEmployee?.id,
  ]);

  useEffect(() => {
    if (defaultDay) {
      setRequest1SelectedDate(new Date(defaultDay));
      setRequest2SelectedDate(new Date(defaultDay));
    }
  }, [defaultDay]);

  const timeWorkers = useCachedFetch(
    "timeWorkersWorkerTime",
    ifCheckForUpdateCache,
    3600,
    false,
    ifClearReturnedValue
  );

  const [requestsTypes] = useFetchAndSetGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/hour-requests-types`
        : `worker-time/${authUserId}/hour-requests-types`,
    startFetchOnInitial: true,
  });

  const [hoursRequest, hoursRequestFetchingState, hoursRequestFetchAgain] =
    useFetchAndSetGET({
      path:
        profileType === ProfileType.SUPERIOR_TIME
          ? `superior-time/hour-requests/${row.id}`
          : `worker-time/${authUserId}/hour-requests/${row.id}`,
      startFetchOnInitial: false,
    });

  const [postRequestBody, setPostRequestBody] = useState<any>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/hour-requests`
        : `worker-time/${authUserId}/hour-requests`,
    method: "POST",
    body: postRequestBody,
    contentType: "application/json",
    setBody: setPostRequestBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (postRequestBody) {
      fetchAgainPostData();
    }
  }, [postRequestBody, fetchAgainPostData]);

  const [putRequestBody, setPutRequestBody] = useState<any>(false);
  const [fetchingStatePutData, fetchAgainPutData] = useFetchOtherThanGET({
    path:
      profileType === ProfileType.SUPERIOR_TIME
        ? `superior-time/hour-requests/${row.id}`
        : `worker-time/${authUserId}/hour-requests/${row.id}`,
    method: "PUT",
    body: putRequestBody,
    contentType: "application/json",
    setBody: setPutRequestBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  useEffect(() => {
    if (putRequestBody) {
      fetchAgainPutData();
    }
  }, [putRequestBody, fetchAgainPutData]);

  useEffect(() => {
    if (
      selectedHoursRequestsType &&
      selectedHoursRequestsType.id === HourRequestType.PrivatePassWithWorkOff
    ) {
      setRequest1SelectedDateObj({
        value: request1SelectedDate,
        setValue: setRequest1SelectedDate,
        min: minDate,
        max: maxDate,
        error: request1SelectedDateError,
      });

      setRequest2SelectedDateObj({
        value: request2SelectedDate,
        setValue: setRequest2SelectedDate,
        min: minDate,
        max: undefined,
        error: request2SelectedDateError,
      });
    } else {
      setRequest1SelectedDateObj(undefined);
      setRequest2SelectedDateObj(undefined);
    }
  }, [
    selectedHoursRequestsType,
    minDate,
    maxDate,
    request1SelectedDate,
    request2SelectedDate,
    request1SelectedDateError,
    request2SelectedDateError,
  ]);

  useEffect(() => {
    if (row.hasOwnProperty("id")) {
      hoursRequestFetchAgain();
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [row, hoursRequestFetchAgain]);

  ////////////////////////
  useEffect(() => {
    if (hoursRequestFetchingState.isError) {
      setIsError(true);
      return;
    }

    if (row.id && hoursRequest && !hoursRequestFetchingState.isFetching) {
      if (timeWorkers === "error") {
        setIsError(true);
        setIfCheckForUpdateCache(false);
        return;
      }

      if (timeWorkers === null) return;

      setSelectedEmployee(timeWorkers.find((v) => v.id === timeWorkerId));

      setSelectedEmployee(
        timeWorkers.find((v) => v.id === hoursRequest.timeWorkerId)
      );

      const selectedHourRequest = requestsTypes.find(
        (v) => v.id === hoursRequest.typeId
      );
      setSelectedHoursRequestsType(selectedHourRequest);

      let dateFromLocal = new Date(hoursRequest.dateFrom);
      let dateToLocal = new Date(hoursRequest.dateTo);

      let dateFromHoursString = convertMinsToHrsMins(
        dateFromLocal.getHours() * 60 + dateFromLocal.getMinutes()
      );
      let dateToHoursString = convertMinsToHrsMins(
        dateToLocal.getHours() * 60 + dateToLocal.getMinutes()
      );

      setRequest1FromHour(dateFromHoursString);
      setRequest1TillHour(dateToHoursString);
      setRequest1SelectedDate(new Date(hoursRequest.dateFrom));

      if (hoursRequest.date2From && hoursRequest.date2To) {
        let date2FromLocal = new Date(hoursRequest.date2From);
        let date2ToLocal = new Date(hoursRequest.date2To);

        let date2FromHoursString = convertMinsToHrsMins(
          date2FromLocal.getHours() * 60 + date2FromLocal.getMinutes()
        );

        let date2ToHoursString = convertMinsToHrsMins(
          date2ToLocal.getHours() * 60 + date2ToLocal.getMinutes()
        );

        setRequest2FromHour(date2FromHoursString);
        setRequest2TillHour(date2ToHoursString);

        if (!haveJoinedRequestsCommonDateField(selectedHourRequest?.id)) {
          setRequest2SelectedDate(new Date(hoursRequest.date2From));
        }
      }

      setComment(hoursRequest.comment);
      setIsLoading(false);
    }
  }, [
    row,
    hoursRequest,
    hoursRequestFetchingState.isFetching,
    hoursRequestFetchingState.isError,
    requestsTypes,
    timeWorkerId,
    timeWorkers,
  ]);

  const applyChanges = useRef(onApplyChanges);
  useEffect(() => {
    if (fetchingStatePostData.response?.status === 201) {
      applyChanges.current(true);
      setPutingPostingCircularProgress(false);
    } else if (fetchingStatePutData.response?.status === 200) {
      applyChanges.current();
      setPutingPostingCircularProgress(false);
    } else if (fetchingStatePostData.response?.status === 400) {
      let error = "";
      Object.keys(fetchingStatePostData.response?.resJson?.errors).forEach(
        (errorObject) => {
          fetchingStatePostData.response?.resJson?.errors[errorObject].forEach(
            (errorString) => {
              error += t(errorString) + " ";
            }
          );
        }
      );
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (fetchingStatePutData.response?.status === 400) {
      let error = "";
      Object.keys(fetchingStatePutData.response?.resJson?.errors).forEach(
        (errorObject) => {
          fetchingStatePutData.response?.resJson?.errors[errorObject].forEach(
            (errorString) => {
              error += t(errorString) + " ";
            }
          );
        }
      );
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(error);
    } else if (fetchingStatePostData.isError) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    } else if (fetchingStatePutData.isError) {
      setPutingPostingCircularProgress(false);
      setResponseErrorMessage(t("something_went_wrong"));
    }
  }, [fetchingStatePostData, fetchingStatePutData, t]);

  useEffect(() => {
    if (
      selectedHoursRequestsType?.id === 19 &&
      selectedEmployee?.id &&
      !timeWorker?.dateOfBirthday
    ) {
      setSelectedHoursRequestsTypeError({
        isError: true,
        errorMessage: t("no_birthday_date_for_selected_employee"),
      });
    } else {
      setSelectedHoursRequestsTypeError({
        isError: false,
        errorMessage: "",
      });
    }
  }, [
    selectedEmployee?.id,
    selectedHoursRequestsType?.id,
    timeWorker?.dateOfBirthday,
    t,
  ]);

  const confirm = () => {
    let ifFetch = true;

    const joinedRequestType = isJoinedRequestType(
      selectedHoursRequestsType?.id
    );
    const joinedRequestsCommonDateField = haveJoinedRequestsCommonDateField(
      selectedHoursRequestsType?.id
    );

    let request1FromHourMinutes: number = 0;
    let request1TillHourMinutes: number = 0;
    let request2FromHourMinutes: number = 0;
    let request2TillHourMinutes: number = 0;

    setSelectedHoursRequestsTypeError({
      isError: false,
      errorMessage: "",
    });

    if (
      selectedHoursRequestsType?.id === 19 &&
      selectedEmployee?.id &&
      !timeWorker?.dateOfBirthday
    ) {
      setSelectedHoursRequestsTypeError({
        isError: true,
        errorMessage: t("no_birthday_date_for_selected_employee"),
      });
      return;
    }

    if (!selectedHoursRequestsType?.id) {
      ifFetch = false;

      setSelectedHoursRequestsTypeError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setSelectedHoursRequestsTypeError({
        isError: false,
        errorMessage: "",
      });
    }

    if (selectedHoursRequestsType?.isCommentRequired && !comment) {
      ifFetch = false;
      setCommentError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setCommentError({
        isError: false,
        errorMessage: "",
      });
    }

    if (profileType === ProfileType.SUPERIOR_TIME) {
      if (!selectedEmployee) {
        setSelectedEmployeeFormError({
          isError: true,
          errorMessage: t("select_employee"),
        });
        ifFetch = false;
      } else {
        setSelectedEmployeeFormError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    if (request1FromHour === "") {
      ifFetch = false;
      setRequest1FromHourError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setRequest1FromHourError({
        isError: false,
        errorMessage: "",
      });
    }

    if (request1TillHour === "") {
      ifFetch = false;
      setRequest1TillHourError({
        isError: true,
        errorMessage: "",
      });
    } else {
      setRequest1TillHourError({
        isError: false,
        errorMessage: "",
      });
    }

    const request1FromHourArray = request1FromHour
      .split(":")
      .map((el) => parseInt(el));
    const request1TillHourArray = request1TillHour
      .split(":")
      .map((el) => parseInt(el));

    request1FromHourMinutes =
      request1FromHourArray[0] * 60 + request1FromHourArray[1];
    request1TillHourMinutes =
      request1TillHourArray[0] * 60 + request1TillHourArray[1];

    if (joinedRequestType) {
      if (request2FromHour === "") {
        ifFetch = false;
        setRequest2FromHourError({
          isError: true,
          errorMessage: "",
        });
      } else {
        setRequest2FromHourError({
          isError: false,
          errorMessage: "",
        });
      }

      if (request2TillHour === "") {
        ifFetch = false;
        setRequest2TillHourError({
          isError: true,
          errorMessage: "",
        });
      } else {
        setRequest2TillHourError({
          isError: false,
          errorMessage: "",
        });
      }

      const request2FromHourArray = request2FromHour
        .split(":")
        .map((el) => parseInt(el));
      const request2TillHourArray = request2TillHour
        .split(":")
        .map((el) => parseInt(el));

      request2FromHourMinutes =
        request2FromHourArray[0] * 60 + request2FromHourArray[1];
      request2TillHourMinutes =
        request2TillHourArray[0] * 60 + request2TillHourArray[1];
    }

    if (request1SelectedDate === null) {
      ifFetch = false;
      setRequest1SelectedDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
    } else if (isNaN(request1SelectedDate.getTime())) {
      ifFetch = false;
      setRequest1SelectedDateError({
        isError: true,
        errorMessage: t("invalid_date"),
      });
    } else if (
      settings.WnioskiOgraniczoneDoDzis?.value !== "0" &&
      settings.WnioskiOgraniczoneDoDzis?.value !== undefined &&
      minDate &&
      maxDate
    ) {
      if (request1SelectedDate < minDate || request1SelectedDate > maxDate) {
        ifFetch = false;
        setRequest1SelectedDateError({
          isError: true,
          errorMessage: t("only_today_allowed"),
        });
      } else {
        setRequest1SelectedDateError({
          isError: false,
          errorMessage: "",
        });
      }
    } else {
      if (
        settings.WebWniosekGodzinowyLiczbaDniWstecz?.value !== "0" &&
        settings.WebWniosekGodzinowyLiczbaDniWstecz?.value !== undefined &&
        minDate
      ) {
        if (request1SelectedDate < minDate) {
          setRequest1SelectedDateError({
            isError: true,
            errorMessage:
              t("max_date_back") +
              ": " +
              settings.WebWniosekGodzinowyLiczbaDniWstecz?.value +
              " " +
              t("days"),
          });
          ifFetch = false;
        } else {
          setRequest1SelectedDateError({
            isError: false,
            errorMessage: "",
          });
        }
      }
    }

    if (joinedRequestType) {
      let isError = false;

      if (request1FromHourMinutes === request1TillHourMinutes) {
        setResponseErrorMessage(t("invalid_time_range"));

        setRequest1FromHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest1TillHourError({
          isError: true,
          errorMessage: "",
        });

        setExpandRequest1(true);
        isError = true;
      }

      if (request2FromHourMinutes === request2TillHourMinutes) {
        setResponseErrorMessage(t("invalid_time_range"));

        setRequest2FromHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest2TillHourError({
          isError: true,
          errorMessage: "",
        });

        setExpandRequest2(true);
        isError = true;
      }

      if (
        request1FromHourMinutes > 0 &&
        request1TillHourMinutes > 0 &&
        request2FromHourMinutes > 0 &&
        request2TillHourMinutes > 0 &&
        !(
          request2FromHourMinutes >= request1TillHourMinutes &&
          request2TillHourMinutes > request1TillHourMinutes
        ) &&
        (joinedRequestsCommonDateField ||
          (!joinedRequestsCommonDateField &&
            request1SelectedDate === request2SelectedDate))
      ) {
        setResponseErrorMessage(t("requests_hours_overlap"));

        setRequest1FromHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest1TillHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest2FromHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest2TillHourError({
          isError: true,
          errorMessage: "",
        });

        isError = true;
      }

      if (isError) {
        return;
      }
    } else {
      if (request1FromHourMinutes === request1TillHourMinutes) {
        setResponseErrorMessage(t("invalid_time_range"));

        setRequest1FromHourError({
          isError: true,
          errorMessage: "",
        });

        setRequest1TillHourError({
          isError: true,
          errorMessage: "",
        });
        return;
      }
    }

    if (joinedRequestType && !joinedRequestsCommonDateField) {
      if (
        request2SelectedDate === null ||
        isNaN(request2SelectedDate.getTime()) ||
        request2SelectedDate < request1SelectedDate
      ) {
        ifFetch = false;
        setRequest2SelectedDateError({
          isError: true,
          errorMessage: t("invalid_date"),
        });
      } else {
        setRequest2SelectedDateError({
          isError: false,
          errorMessage: "",
        });
      }
    }

    setResponseErrorMessage("");
    if (!ifFetch) return;
    setPutingPostingCircularProgress(true);

    let request1DateFrom: string = "";
    let request1DateTo: string = "";
    let request2DateFrom: string = "";
    let request2DateTo: string = "";

    if (request1FromHourMinutes >= request1TillHourMinutes) {
      let dateOneDayAhead = new Date(request1SelectedDate);
      dateOneDayAhead.setDate(dateOneDayAhead.getDate() + 1);
      request1DateFrom = computeDate(
        request1SelectedDate,
        "T" + request1FromHour
      );
      request1DateTo = computeDate(dateOneDayAhead, "T" + request1TillHour);
    } else {
      request1DateFrom = computeDate(
        request1SelectedDate,
        "T" + request1FromHour
      );
      request1DateTo = computeDate(
        request1SelectedDate,
        "T" + request1TillHour
      );
    }

    if (joinedRequestType) {
      if (joinedRequestsCommonDateField) {
        if (request2FromHourMinutes >= request2TillHourMinutes) {
          let dateOneDayAhead = new Date(request1SelectedDate);
          dateOneDayAhead.setDate(dateOneDayAhead.getDate() + 1);
          request2DateFrom = computeDate(
            request1SelectedDate,
            "T" + request2FromHour
          );
          request2DateTo = computeDate(dateOneDayAhead, "T" + request2TillHour);
        } else {
          request2DateFrom = computeDate(
            request1SelectedDate,
            "T" + request2FromHour
          );
          request2DateTo = computeDate(
            request1SelectedDate,
            "T" + request2TillHour
          );
        }
      } else {
        if (request2FromHourMinutes >= request2TillHourMinutes) {
          let dateOneDayAhead = new Date(request2SelectedDate);
          dateOneDayAhead.setDate(dateOneDayAhead.getDate() + 1);
          request2DateFrom = computeDate(
            request2SelectedDate,
            "T" + request2FromHour
          );
          request2DateTo = computeDate(dateOneDayAhead, "T" + request2TillHour);
        } else {
          request2DateFrom = computeDate(
            request2SelectedDate,
            "T" + request2FromHour
          );
          request2DateTo = computeDate(
            request2SelectedDate,
            "T" + request2TillHour
          );
        }
      }
    }

    let timeWorkerId =
      profileType === ProfileType.SUPERIOR_TIME ? selectedEmployee?.id : null;

    if (joinedRequestType) {
      if (row.hasOwnProperty("id")) {
        setPutRequestBody(
          JSON.stringify({
            timeWorkerId,
            dateFrom: request1DateFrom,
            dateTo: request1DateTo,
            date2From: request2DateFrom,
            date2To: request2DateTo,
            comment,
            typeId: selectedHoursRequestsType?.id,
          })
        );
      } else {
        setPostRequestBody(
          JSON.stringify({
            timeWorkerId,
            dateFrom: request1DateFrom,
            dateTo: request1DateTo,
            date2From: request2DateFrom,
            date2To: request2DateTo,
            comment,
            typeId: selectedHoursRequestsType?.id,
          })
        );
      }
    } else {
      if (row.hasOwnProperty("id")) {
        setPutRequestBody(
          JSON.stringify({
            timeWorkerId,
            dateFrom: request1DateFrom,
            dateTo: request1DateTo,
            comment,
            typeId: selectedHoursRequestsType?.id,
          })
        );
      } else {
        setPostRequestBody(
          JSON.stringify({
            timeWorkerId,
            dateFrom: request1DateFrom,
            dateTo: request1DateTo,
            comment,
            typeId: selectedHoursRequestsType?.id,
          })
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      TransitionProps={{
        onExited: () => {
          setPostRequestBody(false);
          setPutRequestBody(false);
          setResponseErrorMessage("");

          setPutingPostingCircularProgress(false);
          setIsLoadingTopRows(false);
          setIsLoading(true);

          setRequest1SelectedDate(new Date());
          setRequest1SelectedDateError({
            isError: false,
            errorMessage: "",
          });

          setRequest2SelectedDate(new Date());
          setRequest2SelectedDateError({
            isError: false,
            errorMessage: "",
          });

          setSelectedHoursRequestsType(null);
          setSelectedHoursRequestsTypeError({
            isError: false,
            errorMessage: "",
          });

          setSelectedEmployee(null);
          setSelectedEmployeeFormError({
            isError: false,
            errorMessage: "",
          });

          setRequest1FromHour("00:00");
          setRequest1TillHour("00:00");
          setRequest2FromHour("00:00");
          setRequest2TillHour("00:00");

          setComment("");

          setIfClearReturnedValue(true);
          setIfCheckForUpdateCache(false);
        },
        onEnter: () => {
          setIfClearReturnedValue(false);
          setIfCheckForUpdateCache(true);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="dialogTitleGlobal">
        <div className="titlePopup">{t("applications_for_hour")}</div>
        {row.id ? (
          <div className="description">{t("adding_an_application")}</div>
        ) : (
          <div className="description">{t("editing_an_application")}</div>
        )}
      </DialogTitle>

      <DialogContent>
        <LoadingWrapper
          isLodadingProgress={isLoading}
          isError={isError}
          setIfFetchAgain={(fetchAgain) => {
            if (fetchAgain) {
              hoursRequestFetchAgain();
            }

            setIfCheckForUpdateCache(fetchAgain);
          }}
          bigSize={true}
          setIsError={setIsError}
        >
          {!isLoading && !isError && (
            <div className="popupContentHours">
              {profileType === ProfileType.SUPERIOR_TIME &&
                selectedEmployee && (
                  <div>
                    <TopRows
                      id={row.id}
                      selectedDate={request1SelectedDate}
                      open={open}
                      setfromHour={setRequest1FromHour}
                      setTillHour={setRequest1TillHour}
                      setIsLoadingTopRows={setIsLoadingTopRows}
                      isLoadingTopRows={isLoadingTopRows}
                      timeWorkerId={selectedEmployee?.id}
                    />
                  </div>
                )}

              {profileType === ProfileType.WORKER_TIME && (
                <div>
                  <TopRows
                    id={row.id}
                    selectedDate={request1SelectedDate}
                    open={open}
                    setfromHour={setRequest1FromHour}
                    setTillHour={setRequest1TillHour}
                    setIsLoadingTopRows={setIsLoadingTopRows}
                    isLoadingTopRows={isLoadingTopRows}
                  />
                </div>
              )}

              {profileType === ProfileType.SUPERIOR_TIME && (
                <div className="typeOfRequestHours">
                  <div className="titleWidthForHours">{t("employee")}:</div>
                  <div className="inputWidthForHours">
                    <AutocompleteStyled
                      id="combo-box-demo1"
                      options={timeWorkers}
                      getOptionLabel={(option: any) =>
                        option.firstName + " " + option.lastName
                      }
                      width={"100%"}
                      isError={selectedEmployeeFormError.isError}
                      value={selectedEmployee ? selectedEmployee : null}
                      onChange={(_, newValue) => {
                        setSelectedEmployee(newValue);
                      }}
                      label={t("employee")}
                      required={true}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setSelectedEmployeeFormError({
                            isError: true,
                            errorMessage: t("select_employee"),
                          });
                        } else {
                          setSelectedEmployeeFormError({
                            isError: false,
                            errorMessage: t(""),
                          });
                        }

                        if (
                          selectedHoursRequestsType?.id === 19 &&
                          selectedEmployee?.id &&
                          !timeWorker?.dateOfBirthday
                        ) {
                          setSelectedHoursRequestsTypeError({
                            isError: true,
                            errorMessage: t(
                              "no_birthday_date_for_selected_employee"
                            ),
                          });
                        } else {
                          setSelectedHoursRequestsTypeError({
                            isError: false,
                            errorMessage: "",
                          });
                        }
                      }}
                    />
                    {selectedEmployeeFormError.isError ? (
                      <div className="errorHolidayrequest">
                        {selectedEmployeeFormError.errorMessage}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="typeOfRequestHours">
                <div className="titleWidthForHours">{t("request_type")}:</div>
                <div className="inputWidthForHours">
                  <AutocompleteStyled
                    options={requestsTypes}
                    getOptionLabel={(option: any) => {
                      return t(option.nameShort);
                    }}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={
                      selectedHoursRequestsType
                        ? selectedHoursRequestsType
                        : null
                    }
                    isError={selectedHoursRequestsTypeError.isError}
                    required={true}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        setSelectedHoursRequestsTypeError({
                          isError: true,
                          errorMessage: t("select_request_type"),
                        });
                      } else {
                        setSelectedHoursRequestsTypeError({
                          isError: false,
                          errorMessage: "",
                        });
                      }
                    }}
                    onChange={(_, newValue) => {
                      setSelectedHoursRequestsType(newValue);
                    }}
                    label={t("request_type")}
                  />
                  {selectedHoursRequestsTypeError.isError ? (
                    <div className="errorHolidayrequest">
                      {selectedHoursRequestsTypeError.errorMessage}
                    </div>
                  ) : null}
                </div>
              </div>

              {isJoinedRequestType(selectedHoursRequestsType?.id) &&
                haveJoinedRequestsCommonDateField(
                  selectedHoursRequestsType?.id
                ) && (
                  <div className="selectDateHoursPopup">
                    <div className="titleWidthForHours">{t("day")}:</div>
                    <div className="inputWidthForHours">
                      <DatePickerStyled
                        view={["year", "month", "date"]}
                        format="dd-MM-yyyy"
                        date={request1SelectedDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        width={"100%"}
                        isError={request1SelectedDateError.isError}
                        setDate={setRequest1SelectedDate}
                      />
                      {request1SelectedDateError.isError ? (
                        <div className="errorMinDateForHours">
                          {request1SelectedDateError.errorMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

              {isJoinedRequestType(selectedHoursRequestsType?.id) ? (
                <>
                  <HoursRequestContent
                    isLoadingTopRows={isLoadingTopRows}
                    date={request1SelectedDateObj}
                    fromHour={{
                      value: request1FromHour,
                      setValue: setRequest1FromHour,
                      error: request1FromHourError,
                      setError: setRequest1FromHourError,
                    }}
                    tillHour={{
                      value: request1TillHour,
                      setValue: setRequest1TillHour,
                      error: request1TillHourError,
                      setError: setRequest1TillHourError,
                    }}
                    title={
                      getJoinedRequestsName(selectedHoursRequestsType?.id, t)[0]
                    }
                    expand={expandRequest1}
                  />

                  <HoursRequestContent
                    isLoadingTopRows={isLoadingTopRows}
                    date={request2SelectedDateObj}
                    fromHour={{
                      value: request2FromHour,
                      setValue: setRequest2FromHour,
                      error: request2FromHourError,
                      setError: setRequest2FromHourError,
                    }}
                    tillHour={{
                      value: request2TillHour,
                      setValue: setRequest2TillHour,
                      error: request2TillHourError,
                      setError: setRequest2TillHourError,
                    }}
                    title={
                      getJoinedRequestsName(selectedHoursRequestsType?.id, t)[1]
                    }
                    expand={expandRequest2}
                  />
                </>
              ) : (
                <>
                  <HoursRequestContent
                    isLoadingTopRows={isLoadingTopRows}
                    date={{
                      value: request1SelectedDate,
                      setValue: setRequest1SelectedDate,
                      min: minDate,
                      max: maxDate,
                      error: request1SelectedDateError,
                    }}
                    fromHour={{
                      value: request1FromHour,
                      setValue: setRequest1FromHour,
                      error: request1FromHourError,
                      setError: setRequest1FromHourError,
                    }}
                    tillHour={{
                      value: request1TillHour,
                      setValue: setRequest1TillHour,
                      error: request1TillHourError,
                      setError: setRequest1TillHourError,
                    }}
                  />
                </>
              )}

              <div className="commentHours">
                <div className="titleWidthForHours">{t("comment")}:</div>
                <div className="inputWidthForHours">
                  <TextFieldStyled
                    label={t("comment")}
                    onBlur={(e) => {
                      setComment(e.target.value);

                      if (e.target.value) {
                        setCommentError({
                          isError: false,
                          errorMessage: "",
                        });
                      } else {
                        setCommentError({
                          isError: true,
                          errorMessage: "",
                        });
                      }
                    }}
                    multiline
                    defaultValue={comment}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 100);
                    }}
                    isError={commentError.isError}
                    required={
                      selectedHoursRequestsType &&
                      selectedHoursRequestsType.isCommentRequired
                    }
                  />

                  {commentError.isError ? (
                    <div className="errorMinDateForHours">
                      {commentError.errorMessage}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </LoadingWrapper>
      </DialogContent>

      <DialogActions className="dialogActionsGlobal">
        <div className="responseErrorMessage">{responseErrorMessage}</div>
        {!putingPostingCircularProgress ? (
          <>
            <Button onClick={onCancelChanges}>{t("cancel")}</Button>
            <Button
              onClick={() => {
                confirm();
              }}
            >
              {t("confirm")}
            </Button>
          </>
        ) : (
          <div className="confirmWaiting">
            <CircularProgress size={30} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditHoursRequestPopup;
