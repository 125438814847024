import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CrewEntryDescentType } from "../../../Constants/crewEntryDescentTypes";
import computeDateRangeFetch from "../../../HelpersFunctions/dateAndTime/convertDateFetch";
import getBeginningOfDate from "../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import {
  AutocompleteFormik,
  DatePickerFormik,
  TextFieldFormik,
} from "../../helpersComponents/FormikInputs";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import {
  DialogContentStyled,
  Row,
  RowInput,
  RowTitle,
} from "../../helpersComponents/PopupHelpers";
import { Guest } from "../../reception/InspectionCompanies/type";
import FormAddNewGuest from "../FormAddNewGuest/FormAddNewGuest";
import styles from "./crewEntryDescentStyle.module.scss";

const PopupContentCrewEntryDescent = ({
  onSubmit,
  rowId,
  eventType,
  profileName,
  confirmationOnly,
}: {
  rowId?: number;
  onSubmit: (values: IAddCrewEntryDescent) => void;
  eventType?: CrewEntryDescentType;
  profileName: string;
  confirmationOnly: boolean;
}) => {
  const { t } = useTranslation();

  const startDate = getBeginningOfDate(new Date(), "Month");
  const [
    arrivalsOfShips,
    fetchingStateArrivalsOfShips,
    fetchingAgainArrivalsOfShips,
  ] = useFetchAndSetGET<ArrivalOfShip[]>({
    path: `${profileName}/arrivals-of-ships?DateFrom=${computeDateRangeFetch(
      startDate
    )}&DateTo=${computeDateRangeFetch(new Date("9999-12-31"))}`,
  });

  const [guests, fetchingStateGuests, fetchingAgainGuests] = useFetchAndSetGET<
    IGuest[]
  >({
    path: `${profileName}/guests`,
  });

  const [fetchedRow, fetchingStateFetchedRow, fetchAgainFetchedRow] =
    useFetchAndSetGET<ICrewEntryDescentGuest>({
      path: `${profileName}/crew-entries-descents/${rowId}`,
      startFetchOnInitial: rowId ? true : false,
    });

  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [addedGuestId, setAddedGuestId] = useState<false | number>(false);
  const [addedGuests, setAddedGuests] = useState<any[]>([]);

  useEffect(() => {
    if (addedGuestId !== false) {
      fetchingAgainGuests();
    }
  }, [addedGuestId, fetchingAgainGuests]);

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateFetchedRow.isFetching}
        isError={fetchingStateFetchedRow.isError}
        setIfFetchAgain={() => {
          if (fetchingStateFetchedRow.isError) {
            fetchAgainFetchedRow();
          }
        }}
      >
        {arrivalsOfShips && guests && (fetchedRow || rowId === undefined) && (
          <DialogContentStyled>
            <Formik
              initialValues={{
                date: fetchedRow
                  ? new Date(fetchedRow.crewEntryDescent.date)
                  : null,
                arrivalOfShip: fetchedRow
                  ? arrivalsOfShips.find(
                      (aos: ArrivalOfShip) =>
                        aos.id === fetchedRow.crewEntryDescent.arrivalOfShipId
                    )
                  : null,
                selectedGuests:
                  fetchedRow && rowId
                    ? guests.filter((guest) =>
                        fetchedRow.guests
                          .map((item) => item.guest.id)
                          .includes(guest.id)
                      )
                      ? guests.filter((guest) =>
                          fetchedRow.guests
                            .map((item) => item.guest.id)
                            .includes(guest.id)
                        )
                      : []
                    : [],
                comments: fetchedRow
                  ? fetchedRow.crewEntryDescent.comments
                  : "",
              }}
              onSubmit={(values) => {
                const object = {
                  date: values.date,
                  arrivalOfShipId: values.arrivalOfShip?.id,
                  guestsIds: values.selectedGuests.map((guest) => guest.id),
                  type: eventType,
                  comments: values.comments,
                } as IAddCrewEntryDescent;
                onSubmit(object);
              }}
              validationSchema={Yup.object({
                date: Yup.date()
                  .nullable()
                  .required(t("field_required"))
                  .test(
                    "testWithArrivalOfShipDate",
                    t("data_cannot_be_earlier_then_ship_arrival_date"),
                    function (value) {
                      return !value ||
                        new Date(
                          this.parent.arrivalOfShip?.dateOfArrival
                        ).setHours(0, 0, 0, 0) >
                          new Date(value).setHours(0, 0, 0, 0)
                        ? false
                        : true;
                    }
                  ),
                arrivalOfShip: Yup.object()
                  .nullable()
                  .required(t("field_required")),
                selectedGuests: Yup.array().test(
                  "isAnyGuestSelected",
                  t("field_required"),
                  (value) => {
                    return value ? value?.length > 0 : false;
                  }
                ),
              })}
            >
              {(formikProps) => (
                <Form noValidate id="submitForm">
                  <LoadingWrapper
                    isLodadingProgress={fetchingStateArrivalsOfShips.isFetching}
                    isError={fetchingStateArrivalsOfShips.isError}
                    setIfFetchAgain={() => {
                      if (fetchingStateArrivalsOfShips.isError) {
                        fetchingAgainArrivalsOfShips();
                      }
                    }}
                  >
                    <Row>
                      <RowTitle>{t("arrival_of_ship")}:</RowTitle>
                      <RowInput>
                        <AutocompleteFormik
                          options={arrivalsOfShips}
                          getOptionLabel={(option: ArrivalOfShip) => {
                            return `${option.shipName} - ${new Date(
                              option.dateOfArrival
                            ).toLocaleDateString()}`;
                          }}
                          width="100%"
                          getOptionSelected={(
                            option: ArrivalOfShip,
                            value: ArrivalOfShip
                          ) => {
                            return option.id === value.id;
                          }}
                          label={t("select_arrival_of_ship")}
                          name="arrivalOfShip"
                          required={true}
                          disabled={confirmationOnly}
                        />
                      </RowInput>
                    </Row>
                  </LoadingWrapper>

                  <Row>
                    <RowTitle>{t("date")}:</RowTitle>
                    <RowInput>
                      <DatePickerFormik
                        label={t("date")}
                        name="date"
                        view={["year", "month", "date"]}
                        format="dd-MM-yyyy"
                        required={true}
                        disabled={confirmationOnly}
                      />
                    </RowInput>
                  </Row>

                  <LoadingWrapper
                    isLodadingProgress={
                      fetchingStateGuests.isFetching ||
                      fetchingStateFetchedRow.isFetching
                    }
                    isError={
                      fetchingStateGuests.isError ||
                      fetchingStateFetchedRow.isError
                    }
                    setIfFetchAgain={() => {
                      if (fetchingStateGuests.isError) {
                        fetchingAgainGuests();
                      }
                      if (fetchingStateFetchedRow.isError) {
                        fetchAgainFetchedRow();
                      }
                    }}
                  >
                    <Row>
                      <RowTitle>{t("persons")}:</RowTitle>
                      <RowInput style={{ display: "flex" }}>
                        <AutocompleteFormik
                          options={guests}
                          getOptionLabel={(option: Guest) => {
                            return `${option.firstName} ${option.lastName}`;
                          }}
                          width="100%"
                          getOptionSelected={(option: Guest, value: Guest) => {
                            return option.id === value.id;
                          }}
                          label={t("select_persons")}
                          name="selectedGuests"
                          required={true}
                          multiple
                          disableCloseOnSelect
                          filterSelectedOptions
                          style={{ width: "300px", marginRight: "10px" }}
                          disabled={confirmationOnly}
                        />
                        <div
                          className={styles.iconShowGuestForm}
                          onClick={() => {
                            if (!confirmationOnly) {
                              setShowAddGuestForm(!showAddGuestForm);
                            }
                          }}
                        >
                          {showAddGuestForm ? (
                            <FontAwesomeIcon
                              className="faMinusNotifications fa-lg"
                              icon={faMinus}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="faPlusNotificationsAgent fa-lg"
                              icon={faPlus}
                            />
                          )}
                        </div>
                      </RowInput>
                    </Row>
                  </LoadingWrapper>
                  {showAddGuestForm && (
                    <FormAddNewGuest
                      addGuestPath={`${profileName}/guests`}
                      setShowAddGuestFrom={setShowAddGuestForm}
                      setAddedGuestId={setAddedGuestId}
                      addedGuestId={addedGuestId}
                      guests={guests}
                      addedGuests={addedGuests}
                      setAddedGuests={setAddedGuests}
                      setFieldValue={formikProps.setFieldValue}
                      isCrewMember={true}
                      selectedGuestsValue={formikProps.values["selectedGuests"]}
                    />
                  )}

                  <Row>
                    <RowTitle>{t("comments")}:</RowTitle>
                    <RowInput>
                      <TextFieldFormik
                        label={t("comments")}
                        name="comments"
                        type="text"
                        multiline={true}
                        rows={3}
                        ifToUpperCaseShipsAndPorts={true}
                        disabled={confirmationOnly}
                      />
                    </RowInput>
                  </Row>
                </Form>
              )}
            </Formik>
          </DialogContentStyled>
        )}
      </LoadingWrapper>
    </>
  );
};

export default PopupContentCrewEntryDescent;
