import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/helpersComponents/Button/button";
import useNavbarsArray from "../components/ProfilesRouting/navbarsArray";
import { ProfileType } from "../enums/profileType";
import useFetchGet from "../hooks/fetchHooks/get/useFetchGet";
import useFetch from "../hooks/fetchHooks/useFetch/useFetch";
import useUpdateAdvancedOptions from "../hooks/useUpdateAdvancedOptions/useUpdateAdvancedOptions";
import useUpdateSettings from "../hooks/useUpdateSettings/useUpdateSettings";
import { selectAdvancedOptions } from "../reducers/advancedOptions";

import {
  selectTimeSuperiorProfile,
  selectTimeWorkerProfile,
  setTimeSuperiorProfile,
  setTimeWorkerProfile,
} from "../reducers/profiles";

import {
  selectACWorkerProfile,
  setACWorkerProfile,
  selectACSuperiorProfile,
  setACSuperiorProfile,
} from "../reducers/profiles";

import { selectAuthUser } from "../reducers/session";
import { selectSettings } from "../reducers/settings";
import {
  selectTimeWorkersSuperiorArray,
  setTimeWorkersSuperior,
} from "../reducers/workersLists";

import {
  selectACWorkersSuperiorArray,
  setACWorkersSuperior,
} from "../reducers/acWorkersList";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import "./loadingSetting.scss";
import useNotificationsSummary from "../hooks/useNotificationsSummary/useNotificationsSummary";
import { getProfileName } from "../HelpersFunctions/profileName";
import useFetchAndSetGET from "../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";

function GetACSuperiorApiProfilePath(
  acSuperiorId,
  profileType: ProfileType
): string {
  switch (profileType) {
    case ProfileType.SUPERIOR_AC:
      return `superior-ac/ac-superiors/${acSuperiorId}/profile`;

    case ProfileType.AGENT:
      return `agent/agents/${acSuperiorId}/profile`;

    case ProfileType.RECEPTION:
      return `reception/receptions/${acSuperiorId}/profile`;

    case ProfileType.GUARD:
      return `guard/guards/${acSuperiorId}/profile`;

    case ProfileType.PFSO:
      return `pfso/pfsos/${acSuperiorId}/profile`;

    case ProfileType.SHIFT_DIRECTOR:
      return `shift-director/shift-directors/${acSuperiorId}/profile`;

    case ProfileType.SECURITY:
      return `security/securities/${acSuperiorId}/profile`;

    case ProfileType.EMPLOYEE:
      return `employee/employees/${acSuperiorId}/profile`;

    case ProfileType.OHS:
      return `ohs/ohss/${acSuperiorId}/profile`;

    case ProfileType.OPERATIONAL_SUPPORT:
      return `operational-support/operational-supports/${acSuperiorId}/profile`;

    case ProfileType.INTEGRATOR:
      return `integrator/integrators/${acSuperiorId}/profile`;

    case ProfileType.EVENTS_PREVIEW:
      return `events-preview/events-previews/${acSuperiorId}/profile`;

    case ProfileType.LOCKERS_MANAGEMENT:
      return `lockers-management/lockers-managements/${acSuperiorId}/profile`;

    default:
      return "";
  }
}

function LoadingSetting() {
  const { t } = useTranslation();
  return (
    <div className="loadingSetting">
      <div>
        <CircularProgress />
      </div>
      <div>{t("loading_settings")}...</div>
    </div>
  );
}

const PrivateWorkerTime: any = ({ component: Component, ...props }) => {
  const settings = useAppSelector(selectSettings);
  const advancedOptions = useAppSelector(selectAdvancedOptions);
  const { t } = useTranslation();

  const [ifUpdateSetting, setIfUpdateSetting] = useState<boolean>(false);
  const [ifUpdateAdvancedOptions, setIfUpdateAdvancedOptions] =
    useState<boolean>(true);

  const dispatch = useAppDispatch();
  const timeWorkerProfile = useAppSelector(selectTimeWorkerProfile);
  const acWorkerProfile = useAppSelector(selectACWorkerProfile);
  const acSuperiorProfile = useAppSelector(selectACSuperiorProfile);
  const timeSuperiorProfile = useAppSelector(selectTimeSuperiorProfile);

  const [ifSetEmpty, setIfSetEmpty] = useState(false);

  const ifSettingsUpdated = useUpdateSettings(ifUpdateSetting, ifSetEmpty);
  const ifAdvancedOptionsUpdated = useUpdateAdvancedOptions(
    ifUpdateAdvancedOptions,
    true
  );

  useNotificationsSummary();

  let authUser: authUserInfo = JSON.parse(
    localStorage.getItem("authUser") || "{}"
  );
  const authUserId = useAppSelector(selectAuthUser).currentProfile.subjectId;
  const authUserType = useAppSelector(selectAuthUser).currentProfile.type;

  let acSuperiorProfiles: ProfileType[] = [];
  acSuperiorProfiles.push(ProfileType.SUPERIOR_AC);
  acSuperiorProfiles.push(ProfileType.AGENT);
  acSuperiorProfiles.push(ProfileType.RECEPTION);
  acSuperiorProfiles.push(ProfileType.GUARD);
  acSuperiorProfiles.push(ProfileType.PFSO);
  acSuperiorProfiles.push(ProfileType.SHIFT_DIRECTOR);
  acSuperiorProfiles.push(ProfileType.SECURITY);
  acSuperiorProfiles.push(ProfileType.EMPLOYEE);
  acSuperiorProfiles.push(ProfileType.OHS);
  acSuperiorProfiles.push(ProfileType.OPERATIONAL_SUPPORT);
  acSuperiorProfiles.push(ProfileType.INTEGRATOR);
  acSuperiorProfiles.push(ProfileType.EVENTS_PREVIEW);
  acSuperiorProfiles.push(ProfileType.LOCKERS_MANAGEMENT);

  //////////////////////////// fetching time workers superior
  const ifFetchTimeWorkersSuperior =
    authUser.currentProfile.type === ProfileType.SUPERIOR_TIME;
  let [
    setIsFetchingTimeWorkersSuperior,
    fetchingStateTimeWorkersSuperior,
    fetchAgainTimeWorkersSuperior,
  ] = useFetch({
    path: "superior-time/time-workers",
    method: "GET",
    startFetchOnInitial: false,
  });
  const timeWorkersSuperiorArray = useAppSelector(
    selectTimeWorkersSuperiorArray
  );
  useEffect(() => {
    if (timeWorkersSuperiorArray !== undefined) return;
    if (ifFetchTimeWorkersSuperior) fetchAgainTimeWorkersSuperior();
  }, [
    ifFetchTimeWorkersSuperior,
    timeWorkersSuperiorArray,
    fetchAgainTimeWorkersSuperior,
  ]);

  useEffect(() => {
    if (
      fetchingStateTimeWorkersSuperior.isError ||
      fetchingStateTimeWorkersSuperior.response === undefined
    )
      return;
    if (!Array.isArray(fetchingStateTimeWorkersSuperior.response.resJson))
      return;
    let data = fetchingStateTimeWorkersSuperior.response.resJson;
    dispatch(setTimeWorkersSuperior(data));

    setIsFetchingTimeWorkersSuperior(false);
  }, [
    fetchingStateTimeWorkersSuperior.isError,
    dispatch,
    fetchingStateTimeWorkersSuperior.response,
    setIsFetchingTimeWorkersSuperior,
    t,
  ]);

  //////////////////////////// fetching ac workers superior
  const supportedACWorkersSuperiorProfiles: number[] = [
    ProfileType.LOCKERS_MANAGEMENT,
  ];

  const ifFetchACWorkersSuperior = supportedACWorkersSuperiorProfiles.includes(
    authUser.currentProfile.type
  );

  const [
    acWorkersSuperior,
    fetchingStateACWorkersSuperior,
    fetchAgainACWorkersSuperior,
  ] = useFetchAndSetGET({
    path: `${getProfileName(authUser.currentProfile.type)}/ac-workers`,
    startFetchOnInitial: false,
  });

  const acWorkersSuperiorArray = useAppSelector(selectACWorkersSuperiorArray);

  useEffect(() => {
    if (acWorkersSuperiorArray !== undefined) {
      return;
    }
    if (ifFetchACWorkersSuperior) {
      fetchAgainACWorkersSuperior();
    }
  }, [
    ifFetchACWorkersSuperior,
    acWorkersSuperiorArray,
    fetchAgainACWorkersSuperior,
  ]);

  useEffect(() => {
    if (
      !fetchingStateACWorkersSuperior.isFetching &&
      !fetchingStateACWorkersSuperior.isError &&
      fetchingStateACWorkersSuperior.response?.status === 200
    ) {
      dispatch(setACWorkersSuperior(acWorkersSuperior));
    }
  }, [
    acWorkersSuperior,
    dispatch,
    fetchingStateACWorkersSuperior.isError,
    fetchingStateACWorkersSuperior.isFetching,
    fetchingStateACWorkersSuperior.response?.status,
  ]);

  ////////////////////////////////////
  const timeWorkerProfileResponse = useFetchGet(
    `worker-time/${authUserId}/time-workers/` + authUserId + "/profile",
    timeWorkerProfile?.ifFetched === false &&
      authUser.currentProfile.type === ProfileType.WORKER_TIME
  );

  const acWorkerProfileResponse = useFetchGet(
    `worker-ac/ac-workers/${authUserId}/profile`,
    acWorkerProfile?.ifFetched === false &&
      authUser.currentProfile.type === ProfileType.WORKER_AC
  );

  const acSuperiorProfileResponse = useFetchGet(
    GetACSuperiorApiProfilePath(authUserId, authUserType),
    acSuperiorProfile?.ifFetched === false &&
      acSuperiorProfiles.includes(authUser.currentProfile.type)
  );

  const timeSuperiorProfileResponse = useFetchGet(
    `superior-time/time-superiors/` + authUserId + "/profile",
    timeSuperiorProfile?.ifFetched === false &&
      authUser.currentProfile.type === ProfileType.SUPERIOR_TIME
  );

  const navbarsArray = useNavbarsArray();
  let ifIgonreSettings = false;
  navbarsArray.forEach((navbarArray) => {
    if (
      navbarArray.profileId === authUser.currentProfile.type &&
      navbarArray?.ifIgonreSettingsPreferences === true
    ) {
      ifIgonreSettings = true;
    }
  });

  useEffect(() => {
    if (timeWorkerProfileResponse.error !== null) {
      dispatch(setTimeWorkerProfile({ ifFetched: "error" }));
    } else if (timeWorkerProfileResponse.data !== null) {
      dispatch(setTimeWorkerProfile(timeWorkerProfileResponse.data));
    }
  }, [timeWorkerProfileResponse, dispatch]);

  useEffect(() => {
    if (acWorkerProfileResponse.error !== null) {
      dispatch(setACWorkerProfile({ ifFetched: "error" }));
    } else if (acWorkerProfileResponse.data !== null) {
      dispatch(setACWorkerProfile(acWorkerProfileResponse.data));
    }
  }, [acWorkerProfileResponse, dispatch]);

  useEffect(() => {
    if (acSuperiorProfileResponse.error !== null) {
      dispatch(setACSuperiorProfile({ ifFetched: "error" }));
    } else if (acSuperiorProfileResponse.data !== null) {
      dispatch(setACSuperiorProfile(acSuperiorProfileResponse.data));
    }
  }, [acSuperiorProfileResponse, dispatch]);

  useEffect(() => {
    if (timeSuperiorProfileResponse.error !== null) {
      dispatch(setTimeSuperiorProfile({ ifFetched: "error" }));
    } else if (timeSuperiorProfileResponse.data !== null) {
      dispatch(setTimeSuperiorProfile(timeSuperiorProfileResponse.data));
    }
  }, [timeSuperiorProfileResponse, dispatch]);

  useEffect(() => {
    if (ifIgonreSettings) {
      return setIfSetEmpty(true);
    }
    setIfUpdateSetting(false);
  }, [ifSettingsUpdated, ifIgonreSettings]);

  useEffect(() => {
    setIfUpdateAdvancedOptions(false);
  }, [ifAdvancedOptionsUpdated]);

  if (
    (settings?.error === true && ifUpdateSetting === false) ||
    (advancedOptions?.error === true && ifUpdateAdvancedOptions === false) ||
    timeWorkerProfile?.ifFetched === "error" ||
    acWorkerProfile?.ifFetched === "error" ||
    (fetchingStateTimeWorkersSuperior.isError && ifFetchTimeWorkersSuperior)
  ) {
    return (
      <div className="loadingSetting">
        <Button
          onClick={() => {
            if (timeWorkerProfile?.ifFetched === "error") {
              dispatch(setTimeWorkerProfile({ ifFetched: false }));
            }

            if (acWorkerProfile?.ifFetched === "error") {
              dispatch(setACWorkerProfile({ ifFetched: false }));
            }

            if (settings?.error === true && ifUpdateSetting === false) {
              setIfUpdateSetting(true);
            }

            if (
              advancedOptions?.error === true &&
              ifUpdateAdvancedOptions === false
            ) {
              setIfUpdateAdvancedOptions(true);
            }

            if (
              fetchingStateTimeWorkersSuperior.isError &&
              ifFetchTimeWorkersSuperior
            ) {
              fetchAgainTimeWorkersSuperior();
            }
          }}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
          &nbsp;&nbsp;
          {t("error_getting_user_settings")}
        </Button>
      </div>
    );
  }
  if (ifUpdateSetting) return <LoadingSetting />;
  if (ifUpdateAdvancedOptions) return <LoadingSetting />;
  if (fetchingStateTimeWorkersSuperior.isFetching && ifFetchTimeWorkersSuperior)
    return <LoadingSetting />;
  if (
    (timeWorkerProfile?.ifFetched === false &&
      authUser.currentProfile.type === ProfileType.WORKER_TIME) ||
    (acWorkerProfile?.ifFetched === false &&
      authUser.currentProfile.type === ProfileType.WORKER_AC)
  )
    return <LoadingSetting />;

  if (settings.ifSettingsFetched === false) return <LoadingSetting />;

  return <Component {...props} />;
};

export default PrivateWorkerTime;
