import "./styles.scss";
import DialogStyled from "../../../helpersComponents/PopupHelpers/dialogStyled";
import DialogTitleStyled from "../../../helpersComponents/PopupHelpers/dialogTitleStyled";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../../helpersComponents/PopupHelpers/dialogActionsStyled";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DialogContentStyled } from "../../../helpersComponents/PopupHelpers";
import TextFieldFormik from "../../../helpersComponents/FormikInputs/TextField/textField";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

interface IProps {
  changePinApiPath: string;
  isOpen: boolean;
  closePopup: () => void;
  successCallback: (newPin: number) => void;
}

function ChangePinPopup({
  changePinApiPath,
  isOpen,
  closePopup,
  successCallback,
}: IProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const [bodyRequest, setBodyRequest] = useState<any>(false);
  const [inputPin, setInputPin] = useState<number>(0);

  const [fetchingStateChnagePin, fetchAgainChangePin] = useFetchOtherThanGET({
    path: changePinApiPath,
    method: "PATCH",
    body: bodyRequest,
    setBody: setBodyRequest,
    contentType: "application/json",
    disableErrorSnackbar: true,
    successCallback: () => {
      successCallback(inputPin);
    },
  });

  useEffect(() => {
    if (fetchingStateChnagePin.response?.isError) {
      enqueueSnackbar(
        t("CANNOT_CHANGE_PIN_BECAUSE_RELATED_AC_WORKER_CANT_BE_FOUND"),
        { variant: "error" }
      );
      /*const allowedErrors: any[] = [
        "CANNOT_CHANGE_PIN_BECAUSE_RELATED_AC_WORKER_CANT_BE_FOUND",
      ];
      if (fetchingStateChnagePin.response?.resJson?.errors) {
        const errors = fetchingStateChnagePin.response?.resJson?.errors;
        Object.keys(errors).forEach((e) => {
          errors[e].forEach((e) => {
            console.log(`ERROR: ${e}`);
            if (allowedErrors.includes(e)) {
              enqueueSnackbar(t(e), { variant: "error" });
            }
          });
        });
      }*/
    }
  }, [fetchingStateChnagePin.response, enqueueSnackbar, t]);

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainChangePin();
    }
  }, [bodyRequest, fetchAgainChangePin]);

  let initValues: {
    pin: number | null;
  } = {
    pin: null,
  };

  return (
    <>
      <DialogStyled
        open={isOpen}
        onClose={closePopup}
        TransitionProps={{
          onExited: () => {},
          onEnter: () => {},
        }}
      >
        <DialogTitleStyled title={t("change_pin")} />
        <DialogContentStyled>
          <Formik
            validateOnBlur={false}
            initialValues={initValues}
            enableReinitialize={true}
            onSubmit={(values: { pin: number | null }) => {
              let body: PathProperty[] = [];

              body.push({
                path: "/pin",
                op: "replace",
                value: values.pin,
              });

              if (values.pin !== null) {
                setInputPin(values.pin);
              }

              setBodyRequest(JSON.stringify(body));
            }}
            validationSchema={Yup.object({
              pin: Yup.number()
                .nullable()
                .required(t("field_required"))
                .test(
                  "len",
                  t("pin_length_must_be_less_than_or_equal_4"),
                  (val) =>
                    val?.toString() !== null &&
                    val?.toString() !== undefined &&
                    val?.toString()?.length <= 4
                ),
            })}
          >
            {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
              return (
                <Form noValidate id="submitForm">
                  <div className="changePin">
                    <div className="titleWidthSubstitutions">Pin:</div>
                    <div className="inputWidthSubstitutions">
                      <TextFieldFormik
                        label={"pin"}
                        name="pin"
                        type="text"
                        multiline={false}
                        ifToUpperCaseShipsAndPorts={true}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContentStyled>
        <DialogActionsStyled
          typConfirm="submit"
          formConfirm="submitForm"
          onConfirmText={t("save")}
          onCancel={() => {
            setErrorMessage("");
            closePopup();
          }}
          isLoading={fetchingStateChnagePin.isFetching}
          errorString={errorMessage}
        />
      </DialogStyled>
    </>
  );
}

export default ChangePinPopup;
